import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/loader/loader';
import {DropdownButton} from '../../components/DropdownButton/DropdownButton';
import TabGroup from '../../components/Tabs/TabGroup';
import Tab from '../../components/Tabs/Tab';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';

import './event.css';

function Event({user}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState({show: false, text: ''});

  const [statusOptions, ] = useState([null, 'DNF', 'DNS'])
  const [eventDrivers, setEventDrivers] = useState([]);
  const [eventConstructors, setEventConstructors] = useState([]);
  const [eventPitStops, setEventPitStops] = useState([]);
  const [event, setEvent] = useState({
    circuitId: 0,
    circuitName: "Circuit Name",
    country: "Country",
    datetime: new Date(),
    eventDate: "",
    eventId: 0,
    eventName: "Event Name",
    eventType: "Event Type",
    eventUrl: "Event Url",
    lat: "Lat",
    locality: "Locality",
    long: "Long",
    round: "0",
    season: 0,
    trackUrl: "Track Url",
    wikiUrl: "Wiki Url",
  });

  
  const [activeTab, setActiveTab] = useState('Race');
  const handleTabChange = (e, newTab) => {
      setActiveTab(newTab);
  }
  
  useEffect(() => {
    fetchEventData();
    fetchEventGridData();
  }, [])

  const goBack = () => {
    navigate(-1);
  }

  const fetchEventData = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    setLoader({show: true, text: 'Loading Event...'});
    fetch(apiUrl + `/app/event/?id=${searchParams.get('event')}`)
      .then(response => response.json())
      .then(data => {
          data[0].datetime = new Date(data[0].eventDate);
          setEvent(data[0]);
          setLoader({show: false});
      })
      .catch((err) => {
          console.log(err);
          setLoader({show: false});
      });
  }

  const fetchEventGridData = () => {
    fetchEventDriversData();
    fetchEventConstructorsData();
    fetchEventPitStopsData();
  }
  const fetchEventDriversData = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: parseInt(searchParams.get('event'))
      })
  };

    setLoader({show: true, text: 'Loading Event Drivers...'});
    fetch(apiUrl + `/app/eventDrivers`, requestOptions)
      .then(response => response.json())
      .then(data => {
          setEventDrivers(data);
          setLoader({show: false});
      })
      .catch((err) => {
          console.log(err);
          setLoader({show: false});
      });
  }


  const fetchEventConstructorsData = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: parseInt(searchParams.get('event'))
      })
  };

    setLoader({show: true, text: 'Loading Event Constructors...'});
    fetch(apiUrl + `/app/eventConstructors`, requestOptions)
      .then(response => response.json())
      .then(data => {
          setEventConstructors(data);
          setLoader({show: false});
      })
      .catch((err) => {
          console.log(err);
          setLoader({show: false});
      });
  }


  const fetchEventPitStopsData = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: parseInt(searchParams.get('event'))
      })
  };

    setLoader({show: true, text: 'Loading Event Pit Stops...'});
    fetch(apiUrl + `/app/eventPitStops`, requestOptions)
      .then(response => response.json())
      .then(data => {
          // if(data!==undefined) {
            setEventPitStops(data);
            // console.log(data.json());
          // }
          setLoader({show: false});
      })
      .catch((err) => {
          console.log(err);
          setLoader({show: false});
      });
  }

  const handleEventDriversChange = (event, driverId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedGrid = JSON.parse(JSON.stringify(eventDrivers)); 
    console.log(`handleEventDriversChange [${event.target.name}] [${driverId}]`);

    for (const [i, g] of Object.entries(updatedGrid)) {
      if (g.driver.id === driverId) {
        if (event.target.name === 'Start Position') {
          updatedGrid[i].startPosition = event.target.value === '' ? null : event.target.value;
          console.log(`Updated ${event.target.name} [${g.driver.id}] to ${event.target.value}`);
        }
        if (event.target.name === 'End Position') {
          updatedGrid[i].endPosition = event.target.value === '' ? null : event.target.value;
          console.log(`Updated ${event.target.name} [${g.driver.id}] to ${event.target.value}`);
        }
        if (event.target.name === 'Laps') {
          updatedGrid[i].lapsCompleted = event.target.value === '' ? null : event.target.value;
          console.log(`Updated ${event.target.name} [${g.driver.id}] to ${event.target.value}`);
        }
        if (event.target.name === 'Status') {
          updatedGrid[i].status = event.target.value === '' ? null : event.target.value;
          console.log(`Updated ${event.target.name} [${g.driver.id}] to ${event.target.value}`);
        }
      }
    }

    setEventDrivers(updatedGrid);
  }


  const handleImportEventDrivers = () => {

    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: parseInt(searchParams.get('event'))
      })
    };

    setLoader({show: true, text: 'Importing Default Grid...'});
    fetch(apiUrl + `/app/eventDrivers/import`, requestOptions)
      .then(data => {
          setLoader({show: false});
      })
      .catch((err) => {
          console.log(err);
          setLoader({show: false});
      });
  }
  
  const handleSaveEventDrivers = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: parseInt(searchParams.get('event')),
        eventDrivers: eventDrivers
      })
    };

    setLoader({show: true, text: 'Updating Grid Start Positions...'});
    fetch(apiUrl + `/app/eventDrivers/update`, requestOptions)
      .then(data => {
          setLoader({show: false});
          fetchEventGridData();
      })
      .catch((err) => {
          console.log(err);
          setLoader({show: false});
      });
  }

  const handleEventDriversStatusChange = (status, driverId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedGrid = JSON.parse(JSON.stringify(eventDrivers)); 
    console.log(`handleEventDriversStatusChange [${driverId}] [${status}]`);
    
    for (const [i, g] of Object.entries(updatedGrid)) {
      if (g.driver.id === driverId) {
        updatedGrid[i].status = status;
        console.log(`Updated Status [${g.driver.id}] to [${status}]`);
      }
    }
    setEventDrivers(updatedGrid);
  }

  const handleEventDriverRadioButtonStatChange = (e, driverId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedGrid = structuredClone(eventDrivers); 
    console.log(`handleEventDriverRadioButtonStatChange [${e.target.name}] [${driverId}]`);
    
    for (const [i, g] of Object.entries(updatedGrid)) {
      if (g.driver.id === driverId) {

        if (e.target.name==='Fastest Lap') {
          // first remove if any other row had stat = true
          updatedGrid.forEach((row)=>row.fastestLap=false);

          // then set the new row with stat
          updatedGrid[i].fastestLap = true;
        }

        if (e.target.name==='DotD') {
          // first remove if any other row had stat = true
          updatedGrid.forEach((row)=>row.dotd=false);

          // then set the new row with stat
          updatedGrid[i].dotd = true;
        }
        console.log(`Updated [${g.driver.id}] to have [${e.target.name}]`);
      }
    }
    setEventDrivers(updatedGrid);
  }

  const [addPitStopModal, setAddPitStopModal] = useState({show: false});
  const openAddPitStop = () => setAddPitStopModal({show: true, eventId: searchParams.get('event')});
  const closeAddPitStop = (action) => {
    if (action==='Add') {

      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;      
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({                
          eventId: addPitStopModal.eventId,
          constructorId: addPitStopModal.constructorId,
          driverId: addPitStopModal.driverId,
          lap: addPitStopModal.lap,
          duration: addPitStopModal.duration,
        })
      }

      setLoader({show: true, text: `Adding pit stop...`});
      fetch(apiUrl + `/app/eventPitStops/add`, requestOptions)
        // .then(response => response.json())
        .then(() => {
          handleSaveEventDrivers(); //recalcuate grid and refetch
          
          setAddPitStopModal({show: false});
          setLoader({show: false});
      })
        .catch((err) => console.log(err));

    } else {
      setAddPitStopModal({show: false});
    }
  }

  const handleEventConstructorSelection = (constructorName) => {
    const constructorMap = new Map();
    eventConstructors.forEach((entry) => {
      constructorMap.set(entry.constructor.name, entry.constructor.id);
    });
    
    let updatedAddPitStopModal = addPitStopModal;

    updatedAddPitStopModal.constructorName = constructorName;
    updatedAddPitStopModal.constructorId = constructorMap.get(constructorName);

    console.log('addPitStopModal)',addPitStopModal);

    setAddPitStopModal({...updatedAddPitStopModal}); // use a spread since setState didn't trigger a re-render
  }

  const handleEventDriverSelection = (driverName) => {
    const driverMap = new Map();
    eventDrivers.forEach((entry) => {
      driverMap.set(entry.driver.lastName, entry.driver.id);
    });
    
    let updatedAddPitStopModal = addPitStopModal;

    updatedAddPitStopModal.driverName = driverName;
    updatedAddPitStopModal.driverId = driverMap.get(driverName);
    console.log(updatedAddPitStopModal);
    console.log(addPitStopModal.constructorId===undefined || addPitStopModal.driverId===undefined || addPitStopModal.lap===undefined || addPitStopModal.duration===undefined);
    setAddPitStopModal({...updatedAddPitStopModal}); // use a spread since setState didn't trigger a re-render
  }

  const handleEventPitStopChange = (e) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedAddPitStopModal = addPitStopModal; 
    console.log(`handleEventPitStopChange ${e.target.name}`);

    if (e.target.name==='Lap') {
      updatedAddPitStopModal.lap = Number(e.target.value);
      console.log(`Updated Lap`);
    }
    
    if (e.target.name==='Duration') {
      updatedAddPitStopModal.duration = Number(e.target.value);
      console.log(`Updated Duration`);
    }
    
    setAddPitStopModal({...updatedAddPitStopModal});
  }

  // const parseRaceData = (data) => {
  //   const rows = data.trim().split('\n');
  //   const headers = rows[0].split('\t');
  
  //   const raceObjects = rows.slice(1).map((row) => {
  //     const values = row.split('\t');
  //     const raceObject = {};
  
  //     headers.forEach((header, index) => {
  //       raceObject[header] = values[index];
  //     });
  
  //     return raceObject;
  //   });
  
  //   return raceObjects;
  // };
  
  return ( 
    <div className="page-container">
      <Loader config={loader}></Loader>

      <Modal show={addPitStopModal.show} onHide={closeAddPitStop}>
          <h2>Add Pit Stop</h2>
          <form className='flex flex-column gap-1'>
              <div className='flex gap-1'>
                <label>Constructor</label>
                <DropdownButton 
                  options={eventConstructors.map((entry) => entry.constructor.name)}
                  value={addPitStopModal.constructorName}
                  clickFunction={(selection) => handleEventConstructorSelection(selection)}
                >
                </DropdownButton>
              </div>

              <div className='flex gap-1'>
                <label>Driver</label>
                <DropdownButton 
                  options={eventDrivers.map((entry) => entry.driver.lastName)}
                  value={addPitStopModal.driverName}
                  clickFunction={(selection) => handleEventDriverSelection(selection)} 
                >
                </DropdownButton>
              </div>
              
              <div>
                <label>Lap</label>
                <input type="number" 
                    name="Lap" 
                    value={addPitStopModal.lap}
                    onChange={(e) => handleEventPitStopChange(e)} />
              </div>

              <div>
                <label>Time</label> 
                <input type="number" 
                    name="Duration" 
                    value={addPitStopModal.duration}
                    onChange={(e) => handleEventPitStopChange(e)} />
              </div>
          </form>

          <div className='flex flex-column gap-1'>
            <Button variant='primary' onClick={() => closeAddPitStop('Add')} disabled={addPitStopModal.constructorId===undefined || addPitStopModal.driverId===undefined || addPitStopModal.lap===undefined || addPitStopModal.duration===undefined}>Add</Button>
            <Button variant='outline-light' onClick={() => closeAddPitStop()}>Cancel</Button>
          </div>
      </Modal>

      <div className="page-header">
        <div>
            <Button 
                variant="link" 
                onClick={(e) => goBack(e)}>
                <NavigateBeforeIcon fontSize="small" />
                Back
            </Button>
        </div>
        <h2>Event</h2>
        <div></div>
      </div>

      <TabGroup>
          <Tab label='Race' activeTab={activeTab} onClick={handleTabChange} />
          <Tab label='Drivers' activeTab={activeTab} onClick={handleTabChange} />
          <Tab label='Constructors' activeTab={activeTab} onClick={handleTabChange} />
          <Tab label='Pit Stops' activeTab={activeTab} onClick={handleTabChange} />
          {user.isAdmin && (<Tab label='⚙' activeTab={activeTab} onClick={handleTabChange} />)}
      </TabGroup>

      <div className='tab-outlet'>
          {activeTab === "Race" && (
          <div className="dark-card">
            <h2>{event.eventName}</h2>
            <p>{`${event.locality}, ${event.country}`}</p>
            <p>{event.datetime.toLocaleString([], {month: 'long', day: 'numeric' })}</p>
            <p>{event.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}</p>
            <b>{event.circuitName}</b>
            <img className="track-map" alt="track" src={event.trackUrl} />
          </div>
          )}
          
          {activeTab === "Drivers" && (
          <div className="dark-card">
            {/* <div className="flex justify-content-end">
              {user.isAdmin && (<Button variant="icon" onClick={toggleSettings} ><SettingsIcon fontSize="small" /></Button>)}
            </div> */}
            
                <table className="table">
                  <thead>
                      <tr>
                          {/* <th>+/-</th> */}
                          <th className='text-align-left'>Driver</th>
                          <th className='td-4em'>Start</th>
                          <th className='td-4em'>Finish</th>
                          <th className='td-4em'>Laps</th>
                          <th>Points</th>
                          {/* <th>Behind</th> */}
                      </tr>
                  </thead>
                  {eventDrivers.length > 0 && (
                  <tbody>
                      {eventDrivers.map(d => (
                      <tr>
                          <td>
                            <div className='flex gap-1'>
                              <img className="answer-image" src={d.driver.profilePicUrl} alt="answer-img" />
                              <div className='flex flex-column'>
                                <div className='text-align-left'>{d.driver.firstName} {d.driver.lastName}</div>
                                <div className='flex align-items-center' style={{gap: '4px'}}>
                                  <img className='constructor-logo-inline' src={d.constructor.logoUrl} alt={d.constructor.name}></img>
                                  <div>{`${d.constructor.name}`}</div>
                                </div>
                                <div className='flex gap-05'>
                                  {d.fastestLap && (<div className='chip'>Fastest Lap</div>)}
                                  {d.dotd && (<div className='chip'>DotD</div>)}
                                  {d.status!==null && (<div className='chip'>{d.status}</div>)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='td-4em'>{d.startPosition}</td>
                          <td className='td-4em'>{d.endPosition}</td>
                          <td className='td-4em'>{d.lapsCompleted}</td>
                          <td>{d.points}</td>
                      </tr>
                      ))}
                  </tbody>
                  )}
              </table>
          </div>
          )}
          
          {activeTab === "Constructors" && (
          <div className="dark-card">
            
              <table className="table">
                  <thead>
                      <tr>
                          <th className='text-align-left'>Constructor</th>
                          <th>Pit Stops</th>
                          <th>Points</th>
                      </tr>
                  </thead>
                  {eventConstructors.length > 0 && (
                  <tbody>
                      {eventConstructors.map(c => (
                      <tr>
                          <td>
                            <div className='flex gap-1'>
                              <img className="answer-image" src={c.constructor.logoUrl} alt={c.constructor.name} />
                              <div className='flex flex-column align-items-start gap-05'>
                                <div>{`${c.constructor.name}`}</div>
                                <div>
                                  {c.fastestPitStopPoints>0 && (<div className='chip'>Fastest Pit Stop</div>)}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td>{c.pitStopCount}</td>
                          <td>{c.points}</td>
                      </tr>
                      ))}
                  </tbody>
                  )}
              </table>
          </div>
          )}
          
          {activeTab === "Pit Stops" && (
          <div className="dark-card">
            {user.isAdmin && (<Button>Import DHL Table</Button>)}

            <table className="table">
              <thead>
                  <tr>
                      <th className='text-align-left'>Constructor</th>
                      <th className='td-4em'>Lap</th>
                      <th className='td-4em'>Driver</th>
                      <th className='td-4em'>Time</th>
                  </tr>
              </thead>
              {eventPitStops.length > 0 && (
              <tbody>
                  {eventPitStops.map(row => (
                  <tr>
                      <td>{row.constructor.name}</td>

                      <td className='td-4em'>{row.lap}</td>
                      
                      <td className='td-4em'>{`${row.driver.lastName}`}</td>
                      
                      <td className='td-4em'>{`${row.duration}`}</td>
                  </tr>
                  ))}
              </tbody>
              )}
            </table>
            {user.isAdmin && (<Button onClick={openAddPitStop}>Add Pit Stop</Button>)}
          </div>
          )}
          
          {activeTab === "⚙" && (
          <div className="dark-card full-width">
            <div className="flex justify-content-end">
              <Button variant="icon" onClick={(e) => fetchEventGridData(e)} ><SyncIcon fontSize="small" /></Button>
            </div>

            <Button onClick={handleImportEventDrivers}>Import Default Drivers</Button>
            <Button onClick={handleSaveEventDrivers}>Save & Refresh</Button>

            {eventDrivers.length > 0 && (
                <table className="table">
                    <thead>
                        <tr>
                            <th className='text-align-left'>Driver</th>
                            <th className='td-4em'>Start</th>
                            <th className='td-4em'>End</th>
                            <th className='td-4em'>Laps</th>
                            <th className='td-4em'>Status</th>
                            <th className='td-4em'>Fastest Lap</th>
                            <th className='td-4em'>DotD</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventDrivers.map(row => (
                        <tr>
                            <td>
                              <div className='flex'>
                                <img className="answer-image" src={row.driver.profilePicUrl} alt="answer-img" />
                                <div className='flex flex-column'>
                                  <div>{row.driver.firstName} {row.driver.lastName}</div>
                                  <div className='flex align-items-center gap-05'>
                                    <img className='constructor-logo-inline' src={row.constructor.logoUrl} alt={row.constructor.name}></img>
                                    <div>{`${row.constructor.name}`}</div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className='td-4em'>
                              <input type="text" 
                                  name="Start Position" 
                                  value={row.startPosition}
                                  onChange={(e) => handleEventDriversChange(e, row.driver.id)} />
                            </td>
                            
                            <td className='td-4em'>
                              <input type="text" 
                                  name="End Position" 
                                  value={row.endPosition}
                                  onChange={(e) => handleEventDriversChange(e, row.driver.id)} />
                            </td>
                            
                            <td className='td-4em'>
                              <input type="text" 
                                  name="Laps" 
                                  value={row.lapsCompleted}
                                  onChange={(e) => handleEventDriversChange(e, row.driver.id)} />
                            </td>
                            
                            <td className='td-4em'>
                                <DropdownButton 
                                  options={statusOptions}
                                  value={row.status}
                                  clickFunction={(status) => handleEventDriversStatusChange(status, row.driver.id)}
                                >
                                </DropdownButton>
                            </td>

                            <td>
                              <input
                                type="radio" 
                                name={`Fastest Lap`}
                                checked={row.fastestLap} 
                                onChange={(e) => handleEventDriverRadioButtonStatChange(e, row.driver.id)} />
                            </td>

                            <td>
                              <input
                                type="radio" 
                                name={`DotD`}
                                checked={row.dotd} 
                                onChange={(e) => handleEventDriverRadioButtonStatChange(e, row.driver.id)} />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                )}
              </div>
              )}
        </div>
    </div>
  );
}

export default Event;