import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from '../../components/Button/Button';
import Linkify from 'react-linkify';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/loader/loader';
import Driver from '../../components/Driver/Driver';
import Constructor from '../../components/Constructor/Constructor';
import Chip from '../../components/Chip/Chip';
import UserChip from '../../components/UserChip/UserChip';
import FormConfigModal from '../form/FormConfigModal';
import SubmissionConfigModal from '../submission/SubmissionConfigModal';
import PointsBreakdown from './PointsBreakdown';
import TabGroup from '../../components/Tabs/TabGroup';
import Tab from '../../components/Tabs/Tab';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';

import './responses.css';

function Responses({user}) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [fetchedData, setFetchedData] = useState(false);
    const [loader, setLoader] = useState({show: false,text: ''});
    const [formConfigModal, setFormConfigModal] = useState({show: false, formId: 0, formState: 0});  
    const [submissionConfigModal, setsubmissionConfigModal] = useState({show: false, submissionId: 0, submissionState: 0});
    const [statModal, setStatModal] = useState({show: false, eventId: 0, type: null, refId: 0});
    const [showSettings, setShowSettings] = useState(JSON.parse(localStorage.getItem("showAdminFormSettings")));
    const [pointsBreakdown, setPointsBreakdown] = useState(false);
    const [answerSubmissions, setAnswerSubmissions] = useState({show: false, submissions: []});
    const [responses, setResponses] = useState({sections: [{questions: {}}]});
    const [tooltip, setTooltip] = useState({show: false, header: '', body: ''});
    
    const [event, setEvent] = useState({ name: '', datetime: ''});
    const [form, setForm] = useState({id: null, state: 'Complete'});
    const [submission, setSubmission] = useState({ id: null });
    
    useEffect(() => {
        console.log('useEffect Responses: ', responses);
        console.log('showAdminFormSettings: ', showSettings);

        if(user.id === null) {
            navigate(`/`);
        } else {
        if(!fetchedData) {        
            fetchResponsesData();
        }
        }
    }, [responses])
  

    const fetchResponsesData = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userId: user.id
            })
        };
    
        setLoader({show: true, text: 'Loading responses...'});
        fetch(apiUrl + `/app/form/responses?id=${searchParams.get('id')}`, requestOptions)
          .then(response => response.json())
          .then(data => {
              setFetchedData(true);
              

              let revisedEventData = {
                ...data.event,
                datetime: new Date(data.event.date)
              };
              setEvent(revisedEventData);
              setForm(data.form);
              setSubmission(data.submission);
              setResponses({
                sections: data.sections
              });
              setLoader({show: false});
      })
          .catch((err) => console.log(err));
      }


      const goBack = () => {navigate(-1);}
      
      const goToForm = () => {
        navigate(`/event/form/?id=${form.id}`);
      }
      
      const goToEvent = () => {        
        navigate(`/event/?event=${event.id}`);
      }

      const goToSubmission = (e) => {
        e.stopPropagation();

        if(submission.id === null) {
            createSubmission(form.id);
        } else {
            navigate(`/event/submission/?id=${submission.id}`);
         }
      }
      const goToReview = (e) => {
        e.stopPropagation();

        if(submission.id === null) {
            createSubmission(form.id);
        } else {
            navigate(`/event/submission/review/?id=${submission.id}`);
        }
      }

      const createSubmission = (formId) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              formId: formId, 
              userId: user.id 
            })
        };
    
        setLoader({show: true, text: 'Creating Submission...'});
        fetch(apiUrl + "/app/submission/create", requestOptions)
          .then(response => response.json())
          .then(data => {
            let submissionId = data.id;
            setLoader({show: false});
            navigate(`/event/submission/?id=${submissionId}`);
      })
          .catch((err) => console.log(err));
      }

      const toggleFormSettings = () => {
        localStorage.setItem("showAdminFormSettings", !showSettings);
        console.log('showAdminFormSettings changed: ', localStorage.getItem("showAdminFormSettings"));
        setShowSettings(!showSettings);
      }

      const openStatModal = (e, type, refId) => {
        e.stopPropagation();
    
        setStatModal({
          show: true,
          eventId: event.id,
          type: type,
          refId: refId
        })
      }
    
      const closeStatModal = () => {
        setStatModal({
          show: false,
          eventId: event.id,
          type: null,
          refId: 0
        })
      }
    
      const openFormConfigModal = () => {
        setFormConfigModal({
          show: true, 
          formId: form.id, 
          formState: form.state, 
          formSubmissionDeadline: form.submissionDeadline
        });
      }
      const closeFormConfigModal = (action) => {
        // if (action !== undefined && action === 'Update') {      
        //   fetchFormData();    
        // }
        
        setFormConfigModal({show: false, formId: 0, formState: 0});    
      }
        
      const openSubmissionConfigModal = () => {
        setsubmissionConfigModal({
          show: true, 
          submissionId: submission.id, 
          submissionState: submission.state
        });
      }
      const closeSubmissionConfigModal = (action) => {
        // if (action !== undefined && action === 'Update') {      
        //   fetchFormData();    
        // }
        
        setsubmissionConfigModal({show: false, submissionId: 0, submissionState: 0});    
      }

      const showTooltip = (e, header, body) => {
        setTooltip({show: true, header: header, body: body})
      }
      const closeTooltip = () => {
        setTooltip({show: false, header: '', body: ''})
      }

      const showPointsBreakdown = (e) => {
        setPointsBreakdown(true)
      }
      const closePointsBreakdown = () => {
        setPointsBreakdown(false);
      }

      const showAnswerSubmissions = (e, submissions) => {
        setAnswerSubmissions({show: true, submissions: submissions});
        console.log(`showAnswerSubmissions`);
        console.log(submissions);
      }
      const closeAnswerSubmissions = () => {
        setAnswerSubmissions({show: false, submissions: []});
      }
    
      const [currentOpenAccordian, setCurrentOpenAccordian] = useState(null);
    
      const openAccordian = (e, a) => {
        e.preventDefault();
        e.stopPropagation();
        
        if(currentOpenAccordian!==a.id) {
          console.log('Open Accordian', a.id);
          setCurrentOpenAccordian(a.id);
        } else {
          setCurrentOpenAccordian(null);
        }
      }
      const isOpenAccordian = (answer) => {
        if(currentOpenAccordian===answer.id) {
          console.log('Open Accordian =>', answer.id);
        }
        return currentOpenAccordian===answer.id;
      }

      const [activeTab, setActiveTab] = useState('Responses');
      const handleTabChange = (e, newTab) => {
          console.log(newTab)
          setActiveTab(newTab);
      }
  
  return (
    <div className="page-container responses">
    
        <Loader config={loader}></Loader>
        
        <FormConfigModal formConfig={formConfigModal} onHide={closeFormConfigModal}></FormConfigModal>
        <SubmissionConfigModal submissionConfig={submissionConfigModal} onHide={closeSubmissionConfigModal}></SubmissionConfigModal>

        <Modal show={tooltip.show} onHide={closeTooltip} center="true" closeOnOverlayClick="true">
            <h2>{tooltip.header}</h2>

            <p>
              <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>
              )}>
                {tooltip.body}
              </Linkify>
            </p>
            
            <div className="modal-footer">
                <Button variant="secondary" onClick={closeTooltip}>Close</Button>
            </div>
        </Modal>        

        {/* {pointsBreakdown && (
        <Modal show={pointsBreakdown} onHide={closePointsBreakdown} center="true" closeOnOverlayClick="true">
            <div className='modal-header'>
                <h2>Points Breakdown</h2>
            </div>
            <div className='modal-content'>
                <PointsBreakdown user={user} formId={form.id}></PointsBreakdown>
            </div>
            
            <div className="modal-footer">
                <Button variant="secondary" onClick={closePointsBreakdown}>Close</Button>
            </div>
        </Modal>
        )}         */}

        <Modal show={answerSubmissions.show} onHide={closeAnswerSubmissions} center="true" closeOnOverlayClick="true">
            <h2>Picks</h2>
            
            <div className="answer-responses-container">
                {answerSubmissions.submissions.map(s => (
                  <UserChip user={s.user[0]}></UserChip>
                ))}
            </div>

            
            <div className="modal-footer">
                <Button variant="secondary" onClick={closeAnswerSubmissions}>Close</Button>
            </div>
        </Modal>      

        <Modal show={statModal.show} onHide={closeStatModal} center="true" closeOnOverlayClick="true">
            <h2>{statModal.type}</h2>

            <div className="modal-footer">
              <Button variant="secondary" onClick={closeStatModal}>Close</Button>
            </div>
        </Modal>


        <div className="page-header">
          <div>
            <Button 
                variant="link" 
                onClick={(e) => goBack(e)}>
                <NavigateBeforeIcon fontSize="small" />
                Back
            </Button>
          </div>
          <h2>Results</h2>
          <div className="flex justify-content-end">
              <Button variant="icon" onClick={(e) => fetchResponsesData(e)} ><SyncIcon fontSize="small" /></Button>
              {user.isAdmin && (<Button variant="icon" onClick={toggleFormSettings} ><SettingsIcon fontSize="small" /></Button>)}
          </div>
        </div>


        {user.isAdmin && showSettings && (
        <div className="settings-container">
            <div className="label-adorner">
            {`{ eId: ${event.id}, fId: ${form.id}, fState: ${form.state}, subId: ${submission.id} }`}
            </div>
            <div className="flex flex-wrap">
                <Button variant="outline-light" onClick={goToForm}>Edit</Button>
                <Button variant="outline-light" onClick={goToSubmission}>Preview</Button>
                <Button variant="outline-light" onClick={goToReview}>Review</Button>
                <Button variant="primary" disabled>Responses</Button>
                <Button variant="outline-light" onClick={openFormConfigModal}>Manage Form</Button>
                <Button variant="outline-light" onClick={openSubmissionConfigModal}>Manage Sub</Button>
            </div>
        </div>
        )}

        
        
 

        {/* <div className=''> */}

          <div className="dark-card event-card">
              <div className='flex justify-content-between gap-1'>
                <div className='grid'>
                  <h2 className='single-line'>{event.name}</h2>
                  <p>{`${event.datetime.toLocaleString([], {weekday: 'long' })},
            ${event.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}
            • ${event.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</p>
                </div>
                <img src={event.posterUrl} className='tiny-poster' alt='poster' onClick={goToEvent} />
              </div>
              {/* <div className='grid'>
                <Button 
                    variant="primary" 
                    onClick={(e) => showPointsBreakdown(e)}>
                    Points Breakdown
                </Button>
              </div> */}
              {form.state !== 'Complete' && (
              <div>
                <div className="results-pending-banner">Results Pending</div>
              </div>
              )}
          </div>

          <TabGroup>
              <Tab label='Responses' activeTab={activeTab} onClick={handleTabChange} />
              <Tab label='Points' activeTab={activeTab} onClick={handleTabChange} />
          </TabGroup>

          <div className='tab-outlet'>
              {activeTab === "Responses" && (<>
                { responses.sections.length > 0 && responses.sections[0].id !== undefined && (
                responses.sections.map(s => (
                
                <div className="dark-card">
                  { s.title !== undefined && (
                  <div className="section-header">
                      <h2>{s.title}</h2>

                      {s.showDescription && (
                      <Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                      )}
                  </div>
                  )}

                  <div className='section-questions-container'>
                    { s.questions.length > 0 && s.questions[0].id !== undefined && s.questions.map(q => (
                    <div>
                        <div className='question-header'>
                            <h4>{q.text}</h4>
                            
                            {q.description !== '' && (
                            <Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                            )}
                        </div>
                        
                        <div className="answer-order-container">
                            {q.answerOrder.map(ao => (
                            <div>
                                <div className='answer-order-header'>

                                  {q.type === 'Podium Picks' && (
                                  <b>
                                    {ao.order === 1 && ('P1')}
                                    {ao.order === 2 && ('P2')}
                                    {ao.order === 3 && ('P3')}
                                  </b>)}

                                  {q.type === 'Driver Picks' && (
                                  <h3>
                                    {ao.order === 1 && ('Group A')}
                                    {ao.order === 2 && ('Group B')}
                                    {ao.order === 3 && ('Group C')}
                                  </h3>)}                              
                                </div>
                                
                                <div className='question-answers-container'>
                                  
                                {q.type === 'Driver Picks' && ao.answers.map(a => (
                                    <Driver driver={a} variant='responses'
                                      expanded={isOpenAccordian(a)} 
                                      handleExpansion={openAccordian}
                                      responsesClick={(e, s) =>showAnswerSubmissions(e, s)}></Driver>
                                  ))}

                                  {q.type === 'Constructor Picks' && ao.answers.map(a => (
                                    <Constructor constructor={a} variant='responses'
                                      expanded={isOpenAccordian(a)} 
                                      handleExpansion={openAccordian}
                                      responsesClick={(e, s) =>showAnswerSubmissions(e, s)}></Constructor>
                                  ))}
                                  
                                  {q.type === 'Bonus Radio' && ao.answers.map(a => (
                                    <div className={`dark-card gap-0`}>

                                      <div className='answer background-image-contain'
                                        style={{backgroundImage: `url(${a.logoUrl})`}}>

                                        <div className='answer-details'>
                                          <h3 className='le-line'>{a.text}</h3>
                                        </div>
                                      
                                        <div className='points'>
                                          
                                          <Chip>{a.points}</Chip>
                                        </div>
                                      </div>
                                      
                                      <div className="submission-avatars" onClick={(e) => showAnswerSubmissions(e, a.submissions)}>
                                        {a.submissions.map(sub => (
                                        <UserChip variant='avatar' user={sub.user[0]}></UserChip>
                                        ))}
                                      </div>
                                  </div>
                                  ))}
                                  
                                  {q.type === 'Podium Picks' && ao.answers.map(a => (
                                    <div className={`dark-card gap-0`}>

                                      <div className='answer background-image-contain'
                                        style={{backgroundImage: `url(${a.logoUrl})`}}>

                                        <div className='answer-details'>
                                          <div>{a.order}</div>
                                          <div className="driverCode">{a.code}</div>
                                        </div>
                                      
                                        <div className='points'>
                                          
                                          <Chip>{a.points}</Chip>
                                        </div>
                                      </div>
                                      
                                      <div className="submission-avatars" onClick={(e) => showAnswerSubmissions(e, a.submissions)}>
                                        {a.submissions.map(sub => (
                                        <UserChip variant='avatar' user={sub.user[0]}></UserChip>
                                        ))}
                                      </div>
                                  </div>
                                  ))}

                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                    ))}
                  </div>
                </div>
                ))
              )}
              </>)}

              
              {activeTab === "Points" && (<>
                <div className="dark-card">
                  <PointsBreakdown user={user} formId={form.id}></PointsBreakdown>
                </div>
              </>)}
          </div>
        {/* </div> */}
    </div>
    )
}

export default Responses;