import React from 'react';
import './Chip.css';

// Usage: <Chip variant="primary"></Chip>
// Variant:
//      (undefined)
//      primary
//      accent
//      warn

function Chip({children, className, ...attributes}) {
    return (
        <div className={`chip ${className}`} {...attributes}>
            {children}
        </div>
    )
}

export default Chip;