import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button/Button';
import Countdown from '../../components/Countdown/Countdown';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../components/loader/loader';
import './schedule.css';

function Schedule({user}) {
    const navigate = useNavigate();

    const [schedule, setSchedule] = useState([]);    
    const [year, setYear] = useState(2024);
    const [highlighedEvent, setHighlighedEvent] = useState();
    const [showCountdown, setShowCountdown] = useState(false);
    const [lastRace, setLastRace] = useState(undefined);
    const [loader, setLoader] = useState({show: false, text: ''});
    
    useEffect(() => {
      console.log('useEffect Schedule: ', schedule);
      fetchScheduleData(year)
    }, [user])

    const fetchScheduleData = (year) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            year: year,
            type: 'Upcoming',
            userId: user.id
          })
      };
  
      setLoader({show: true, text: 'Loading Schedule...'});

      fetch(apiUrl + `/app/schedule`, requestOptions)
        .then(response => response.json())
        .then(data => {

            let revisedData = data.map(event => ({
              ...event,
              datetime: new Date(event.date)
            }));
            setSchedule(revisedData);

            if(!showCountdown) {
              setHighlighedEvent(revisedData[0]);
            }

            if(user.id !== null) {
              fetchLastRaceData();
            } else {
              setLoader({show: false});
            }
    })
        .catch((err) => {
          console.log(err);
          setLoader({show: true, text: 'Error, try again.'});
        });
    }

    const fetchLastRaceData = () => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            year: year,
            userId: user.id
          })
      };
  
      setLoader({show: true, text: 'Loading Schedule...'});
      fetch(apiUrl + `/app/schedule/lastRace`, requestOptions)
        .then(response => response.json())
        .then(data => {
          
            let revisedData = {
              ...data,
              datetime: new Date(data.date)
            };
            setLastRace(revisedData);
            
            setLoader({show: false});
        })
        .catch((err) => {
          console.log(err);
          setLoader({show: true, text: 'Error, try again.'});
        });
    }

    const goToEvent = (e, event) => {
      e.stopPropagation();
      
      navigate(`/event/?event=${event.eventId}`);
    }

    const goToForm = (e, event) => {
      e.stopPropagation();

      if(event.formId === null) {
        createForm(event.eventId);
      } else {
        navigate(`/event/form/?id=${event.formId}`);
      }
    }
  
    const goToSubmission = (e, event) => {
      e.stopPropagation();

      if(event.submissionId === null) {
        createSubmission(event.formId);
      } else {
        navigate(`/event/submission/?id=${event.submissionId}`);
      }
    }

    const goToReview = (e, event) => {
      e.stopPropagation();

      navigate(`/event/submission/review?id=${event.submissionId}`);
    }

    const createSubmission = (formId) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            formId: formId, 
            userId: user.id 
          })
      };

      setLoader({show: true, text: 'Creating Submission...'});
      fetch(apiUrl + "/app/submission/create", requestOptions)
        .then(response => response.json())
        .then(data => {
          let submissionId = data.id;
          setLoader({show: false});
          navigate(`/event/submission/?id=${submissionId}`);
    })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }
    


    const createForm = (eventId) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              eventId: eventId 
          })
      };

      setLoader({show: true, text: 'Creating Form...'});
      fetch(apiUrl + "/app/form/create", requestOptions)
        .then(response => response.json())
        .then(data => {
          let formId = data.id;
          setLoader({show: false});
          addFormSection(formId);
    })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }

    const addFormSection = (formId) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            formId: formId, 
            title: null,
            description: null, 
            showDescription: false, 
            afterSection: null
          })
      };

      setLoader({show: true, text: 'Creating Form Section...'});
      fetch(apiUrl + "/app/section/create", requestOptions)
        .then(response => response.json())
        .then(() => {
          setLoader({show: false});
          navigate(`/event/form/?id=${formId}`);
        })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }
  
    return ( 
      <div className="page-container schedule" style={{background: `linear-gradient(${highlighedEvent !== undefined ? highlighedEvent.accentColor : '#000'} 300px, rgba(14, 14, 14, 0) 800px)`}}>
        <Loader config={loader}></Loader>

        { user.id !== null && lastRace !== undefined && !showCountdown && (
        <div className='flex justify-content-center' style={{marginTop: '0.5em'}}>
          <Button variant='link' onClick={() => {navigate(`/event/form/responses/?id=${lastRace.formId}`)}}>View {lastRace.country} Race Results</Button>
        </div>
        )}

        {showCountdown && (
          <Countdown targetDate="2024-03-01T05:00:00" />
        )}

        {highlighedEvent !== undefined && (
        <div className="highlight-event" onClick={(e) => goToEvent(e, highlighedEvent)}
          style={{backgroundImage: `linear-gradient(to bottom, 
            rgba(0, 0, 0, 0) 30%, 
            ${highlighedEvent.accentColor}), 
            url(${highlighedEvent.posterUrl})
            `}}>
          <img className="circuit-map" alt="track" src={highlighedEvent.trackSvgUrl} />
          <div className="subtitle">
            <div>ROUND {highlighedEvent.round}</div>
            
            {/* {highlighedEvent.sprintRace && (
            <div className='sprint-label'>Sprint</div>
            )} */}
          </div>

          <img className="flag" alt={highlighedEvent.locality} src={highlighedEvent.flagUrl} />
          <div>{highlighedEvent.locality}</div>
          <h2>{highlighedEvent.eventName}</h2>
          <div>{`${highlighedEvent.datetime.toLocaleString([], {weekday: 'long' })},
            ${highlighedEvent.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}
            • ${highlighedEvent.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</div>
          { user.id !== null ? (
            <div className="event-action">
                {highlighedEvent.formState === 'Live' ? (
                    highlighedEvent.submissionState !== 'Submitted' ? (
                    <Button variant="primary" onClick={(e) => goToSubmission(e, highlighedEvent)}>Submit Picks!</Button>
                    ):(
                    <Button variant="primary" onClick={(e) => goToReview(e, highlighedEvent)}>View Picks!</Button>                      
                    )
                  ) : (
                  <Button variant="outline-light" disabled >Submissions Open Soon!</Button>
                )}
            </div>
          ) : ''}
        </div>
        )}
      
        <div className="event-list">
          {schedule.length > 0 && (
            schedule.map(event => (
              <div className="event" key={event.eventId} onClick={(e) => goToEvent(e, event)}>
                <div className="time-container">
                  {/* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat */}
                  <div className='weekday'>{event.datetime.toLocaleString([], {weekday: 'long' })}</div>
                  <div className='date'>{event.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}</div>
                  <div className='time'>{event.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit'})}</div>
                  <div className='timezone'>{event.datetime.toLocaleString([], {day:'2-digit',timeZoneName: 'short' }).substring(4)}</div>
                </div>

                <h2 className='event-name no-wrap'>{event.eventName}</h2>

                <div className="locality-container">
                  <img className="flag small" alt={event.locality} src={event.flagUrl} />
                  <div>{event.locality}</div>
                </div>

                <div className="circuit-container">
                  <img className="circuit-map small" alt={event.circuitName} src={event.trackSvgUrl} />
                  <div>{event.circuitName}</div>
                </div>

                <div className="round-container">
                  <div>Round {event.round}</div>
                  {/* {event.sprintRace && (
                  <div className='chip sprint-label'>Sprint</div>
                  )} */}
                  {user.isAdmin && (
                  <Button variant="outline-light" className="chip" onClick={(e) => goToForm(e, event)}>
                    Edit <EditIcon fontSize="small"/>
                  </Button>
                  )}
                </div>
              </div>
            ))
          )}
          {/* <div className="end-of-season"></div> */}
        </div>
      </div>
    );
}

export default Schedule;