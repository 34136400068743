import React from 'react';
import './Tabs.css';

// Usage: 

// const [activeTab, setActiveTab] = useState('One');
// const handleTabChange = (e, newTab) => setActiveTab(newTab);

// return (
//   <TabGroup 
//     value={activeTab}
//     onChange={handleTabChange}>
//     <Tab>One</Tab>
//     <Tab>Two</Tab>
//     <Tab>Three</Tab>
//   </TabGroup>
// )

function TabGroup({children, className, ...attributes}) {
    return (
        <div className={`tab-group ${className}`} {...attributes}>
            {children}
        </div>
    )
}

export default TabGroup;