import React from 'react';
import './Button.css';

// Usage: <Button variant="primary"></Button>
// Variant:
//      (undefined)
//      primary
//      accent
//      warn

function Button({children, className, ...attributes}) {
    return (
        <button className={`button ${className}`} {...attributes}>
            {children}
        </button>
    )
}

export default Button;