import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import Loader from '../../components/loader/loader';
import Button from '../../components/Button/Button';
import Driver from '../../components/Driver/Driver';
import Constructor from '../../components/Constructor/Constructor';
import Modal from '../../components/Modal/Modal';
import Linkify from 'react-linkify';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import FormConfigModal from '../form/FormConfigModal';
import SubmissionConfigModal from './SubmissionConfigModal';
import './submission.css';

function Submission({user}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState({show: false, text: ''});
  const [tooltip, setTooltip] = useState({show: false, header: '', body: ''});
  const [showSection, setShowSection] = useState(0);
  const [fetchedData, setFetchedData] = useState(false);
  const [formConfigModal, setFormConfigModal] = useState({show: false, formId: 0, formState: 0});
  const [submissionConfigModal, setsubmissionConfigModal] = useState({show: false, submissionId: 0, submissionState: 0});
  const [showSettings, setShowSettings] = useState(JSON.parse(localStorage.getItem("showAdminFormSettings")));
  
  const [event, setEvent] = useState({name: ''});
  const [form, setForm] = useState({id: null});
  const [submission, setSubmission] = useState({id: null, section: [{question: {}}]});


  useEffect(() => {
    console.log('useEffect Submission: ', submission);
    console.log('showAdminFormSettings: ', showSettings);

    if(user.id === null) {
        navigate(`/`);
    } else {
      if(!fetchedData) {        
        fetchSubmissionData();
      }
    }
  }, [submission])


  const fetchSubmissionData = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: user.id
        })
    };

    setLoader({show: true, text: 'Loading submission...'});
    fetch(apiUrl + `/app/submission/?id=${searchParams.get('id')}`, requestOptions)
      .then(response => response.json())
      .then(data => {
          setFetchedData(true);
          setEvent(data.event);
          setForm(data.form);
          setSubmission({
            ...data.submission,
            section: data.section
          });


          if(searchParams.get('section') === 'Review') {
            setShowSection(data.section.length);
            console.log(showSection);
            searchParams.delete('section');
          }
          setLoader({show: false});
  })
      .catch((err) => console.log(err));
  }


  const goHome = () => {
    navigate(`/`); 
  }
  
  const goToForm = () => {
    navigate(`/event/form/?id=${form.id}`);
  }
  
  const goToReview = () => {
    navigate(`/event/submission/review?id=${submission.id}`);
  }
  const goToResponses = () => {
    navigate(`/event/form/responses/?id=${form.id}`);    
  }
  
  const handleSectionChange = (e, direction) => {
    console.log(`Section change. Current (${showSection}), Dir (${direction})`);

    let newSection = showSection + direction;

    if (newSection < 0) 
      {setShowSection(0)}
    else if  (newSection > submission.section.length)
      {setShowSection(submission.section.length)}
    else 
      {setShowSection(newSection)}
      
  }

  const handleUserAnswerChange = (event, sectionId, questionId, answerId, order) => {

    // if(form.state==='Complete') return
    if(submission.state==='Submitted') return

    //deep object copy is needed otherwise UseEffect wont fire
    let updatedSubmission;
    try {
      updatedSubmission = structuredClone(submission); 
    } catch {
      console.log('Y structuredClone no work')
    }

    console.log(`handleUserAnswerChange [${event.target.id}] [${sectionId}] [${questionId}] [${answerId}]`);

    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    for (const [i, s] of Object.entries(submission.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(submission.section[i].question)) {
          if (q.id === questionId) {
            for (const [k, a] of Object.entries(submission.section[i].question[j].answer)) {
              if (a.id === answerId) {
                // if (event.target.getAttribute('name').startsWith('User Answer Radio')) {
                  updatedSubmission.section[i].question[j].answer.forEach((el)=>{if(el.userAnswer === order) {el.userAnswer = undefined}});

                  const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({
                        submissionId: submission.id, 
                        questionId: questionId,
                        answerId: answerId,
                        order: order,
                        replace: true
                      })
                  };
              
                  setLoader({show: true, text: 'Saving selection...'});
                  fetch(apiUrl + "/app/submission/answer/set", requestOptions)
                    .then(response => response.json())
                    .then(() => {
                      updatedSubmission.section[i].question[j].answer[k].userAnswer = order;
                      setSubmission(updatedSubmission);
                      setLoader({show: false});
                  })
                    .catch((err) => console.log(err));
                // } else {
                //   console.log(`Unknown input name: ${event.target.name} [${a.id}]`);
                // }
              }
            }
          }
        }
      }
    }     
  }

  const toggleFormSettings = () => {
    localStorage.setItem("showAdminFormSettings", !showSettings);
    console.log('showAdminFormSettings changed: ', localStorage.getItem("showAdminFormSettings"));
    setShowSettings(!showSettings);
  }

  // const openStatAccordian = (e, type, refId) => {
  //   e.stopPropagation();

  //   setStatModal({
  //     show: true,
  //     eventId: event.id,
  //     type: type,
  //     refId: refId
  //   })
  // }
  const [currentOpenAccordian, setCurrentOpenAccordian] = useState(null);

  const openAccordian = (e, a) => {
    e.preventDefault();
    e.stopPropagation();
    
    if(currentOpenAccordian!==a.id) {
      console.log('Open Accordian', a.id);
      setCurrentOpenAccordian(a.id);
    } else {
      setCurrentOpenAccordian(null);
    }
  }
  const isOpenAccordian = (answer) => {
    if(currentOpenAccordian===answer.id) {
      console.log('Open Accordian =>', answer.id);
    }
    return currentOpenAccordian===answer.id;
  }

  // const openStatAccordian = () => {
    // setStatModal({
    //   show: false,
    //   eventId: event.id,
    //   type: null,
    //   refId: 0
    // })
  // }

  const openFormConfigModal = () => {
    setFormConfigModal({
      show: true, 
      formId: form.id, 
      formState: form.state, 
      formSubmissionDeadline: form.submissionDeadline
    });
  }
  const closeFormConfigModal = (action) => {
    if (action !== undefined && action === 'Update') {      
      fetchSubmissionData();    
    }
    
    setFormConfigModal({show: false, formId: 0, formState: 0});    
  }
    
  const openSubmissionConfigModal = () => {
    setsubmissionConfigModal({
      show: true, 
      submissionId: submission.id, 
      submissionState: submission.state
    });
  }
  const closeSubmissionConfigModal = (action) => {
    if (action !== undefined && action === 'Update') {      
      fetchSubmissionData();    
    }
    
    setsubmissionConfigModal({show: false, submissionId: 0, submissionState: 0});    
  }

  const showTooltip = (e, header, body) => {
    setTooltip({show: true, header: header, body: body})
  }
  const closeTooltip = () => {
    setTooltip({show: false, header: '', body: ''})
  }

  return ( 
    <div className="page-container submission-component">
      <Loader config={loader}></Loader>

      <FormConfigModal formConfig={formConfigModal} onHide={closeFormConfigModal}></FormConfigModal>
      <SubmissionConfigModal submissionConfig={submissionConfigModal} onHide={closeSubmissionConfigModal}></SubmissionConfigModal>

      <Modal show={tooltip.show} onHide={closeTooltip} center="true" closeOnOverlayClick="true"  className="form-config-modal">
          <h2>{tooltip.header}</h2>
          <p>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>
            )}>
              {tooltip.body}
            </Linkify></p>

          <div className="modal-footer">
            <Button variant="secondary" onClick={closeTooltip}>Close</Button>
          </div>
      </Modal>      

      {/* <Modal show={statModal.show} onHide={closeStatModal} center="true" closeOnOverlayClick="true">
          <h2>{statModal.type}</h2>

          <div className="modal-footer">
            <Button variant="secondary" onClick={closeStatModal}>Close</Button>
          </div>
      </Modal> */}

      <div className="flex justify-content-between">
        <ul className="breadcrumb">
          <li className="back-btn" onClick={goHome}>
            <HomeIcon fontSize="small"/>
            <span>Home</span>
          </li>
          <li>
            <span>{`Round ${event.round}`}</span>
          </li>
        </ul>
        {user.isAdmin && (<Button variant="icon" onClick={toggleFormSettings} ><SettingsIcon fontSize="small" /></Button>)}
      </div>

      {user.isAdmin && showSettings && (
      <div className="settings-container">
        <div className="label-adorner">
          {`{ eId: ${event.id}, fId: ${form.id}, fState: ${form.state}, subId: ${submission.id} }`}
        </div>
        <div className="flex flex-wrap">
          <Button variant="outline-light" onClick={goToForm}>Edit</Button>
          <Button variant="primary" disabled>Preview</Button>
          <Button variant="outline-light" onClick={goToReview}>Review</Button>
          <Button variant="outline-light" onClick={goToResponses}>Responses</Button>
          <Button variant="outline-light" onClick={openFormConfigModal}>Manage Form</Button>
          <Button variant="outline-light" onClick={openSubmissionConfigModal}>Manage Sub</Button>
        </div>
      </div>
      )}

      <h2>{event.name}</h2>      

      <form className="submission" onSubmit={e => {e.preventDefault();}}>

        <div className="form-header">
          
          <div className="form-nav">
            <div>
              <Button 
                variant="icon" 
                onClick={(e) => handleSectionChange(e, -1)} 
                disabled={showSection <= 0} >
                  <NavigateBeforeIcon fontSize="small" />
              </Button>
            </div>
            
            <div className='form-nav-label'>
              {showSection < submission.section.length && (
              <div>{`${showSection+1}/${submission.section.length}`}</div>
              )}
            </div>

            <div>
              {submission.section.length > 0 && showSection < submission.section.length-1 && (
              <Button 
                variant="primary" 
                style={{marginLeft: 1 + 'em'}}
                onClick={(e) => handleSectionChange(e, 1)}>
                  Next<NavigateNextIcon fontSize="small" />
              </Button>
              )}            

              {submission.section.length > 0 && showSection === submission.section.length-1 && (
              <Button 
                variant="primary"
                style={{marginLeft: 1 + 'em'}}
                onClick={(e) => goToReview()}>
                  Review<NavigateNextIcon fontSize="small" />
              </Button>
              )}



            </div>
          </div>
        </div>
        

        {/* <div className="form-content"> */}
        { submission.section.length > 0 && showSection < submission.section.length ? (<>
        { submission.section[0].id !== undefined && (
          submission.section.slice(showSection, showSection+1).map(s => (
            <div className="dark-card section margin-inline-auto scrollable" key={`s${s.id}`} id={s.id}>

              { s.title!==undefined && (
              <div className="section-header">
                  <h2>{s.title}</h2>

                  {s.showDescription && (
                  <Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                  )}
              </div>
              )}
              
              { s.question.length > 0 && s.question[0].id !== undefined && (
                s.question.map(q => (<>
                <div className='question-header'>
                  <div className="flex justify-content-between align-items-center">
                    <h3>{q.text}</h3>
                    {q.showDescription && (
                    <Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                    )}
                  </div>

                  {q.type === 'Podium Picks' && (
                  <div className="podium-picks-question">
                    <div>Select podium drivers:</div>
                    <div className="podium-picks-selections">
                      <div>1<sup>st</sup></div>
                      <div>2<sup>nd</sup></div>
                      <div>3<sup>rd</sup></div>
                    </div>
                  </div>
                  )}
                </div>


                { q.answer.length > 0 && q.answer[0].id !== undefined && (<>    
                
                    {q.type === 'Driver Picks' && q.answer.map(a => (
                    <div>
                      {a.startPosition===1 && (<h3>Group A</h3>)}
                      {a.startPosition===6 && (<h3>Group B</h3>)}
                      {a.startPosition===11 && (<h3>Group C</h3>)}

                      <Driver driver={a} variant='input' 
                        expanded={isOpenAccordian(a)} 
                        handleExpansion={openAccordian} 
                        inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                        disabled={submission.state==='submitted'}></Driver>
                    </div>
                    ))}

                    {/* {q.type === 'Driver Picks' && q.answer.map((a, i) => (
                    <div>
                      {a.startPosition===1 && (<h3>Group A</h3>)}
                      {a.startPosition===6 && (<h3>Group B</h3>)}
                      {a.startPosition===11 && (<h3>Group C</h3>)}

                      <div className={`dark-card selectable gap-0
                        ${a.userAnswer!==undefined ? 'selected' : ''} 
                        ${submission.state==='submitted' ? 'disabled' : ''}`}

                        onClick={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 
                          a.startPosition<=5 ? 1 : (
                            a.startPosition<=10 ? 2 : 3)
                        )} >

                        <div className="answer">
                          <div className='number'>
                            <h3>{a.status !== null ? a.status : a.startPosition}</h3>
                          </div>

                          <div className='input'>
                              {a.startPosition<=5 && (
                              <input
                                type="radio" 
                                name={`User Answer Radio [${a.id}] 1`}
                                checked={a.userAnswer===1} 
                                onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 1)} 
                                disabled={submission.state==='Submitted'} />)}

                              {a.startPosition>5 && a.startPosition<=10 && (
                              <input
                                type="radio" 
                                name={`User Answer Radio [${a.id}] 2`}
                                checked={a.userAnswer===2} 
                                onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 2)} 
                                disabled={submission.state==='Submitted'} />)}

                              {a.startPosition>10 && (<input
                                type="radio" 
                                name={`User Answer Radio [${a.id}] 3`}
                                checked={a.userAnswer===3} 
                                onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 3)} 
                                disabled={submission.state==='Submitted'} />)}
                              
                          </div>
                          
                          <div className="driver-details driver-answer">
                            <img className="answer-image medium" src={a.profilePicUrl} alt="answer-img" />

                            <div className='flex flex-column'>
                              <div className='single-line'>{a.firstName}</div>
                              <h3 className='single-line'>{a.lastName}</h3>
                              <div className='flex align-items-center' style={{gap: '4px'}}>
                                <img className='constructor-logo-inline' src={a.constructorLogoUrl} alt={a.constructorName}></img>
                                <div className='single-line'>{`${a.constructorName}`}</div>
                              </div>
                            </div>
                          </div>
                          
                          <div className='stats'>
                            <Button variant='icon-outline' onClick={(e) => openAccordian(e, a)}>
                              <InsertChartOutlinedIcon fontSize='small' />
                            </Button>
                          </div>
                        </div>
                        
                        
                        <div className={`stats-accordian ${isOpenAccordian(a) ? 'open' : ''}`}>
                          <p>Last Race:</p>
                          <p>Qualifying: </p>
                        </div>
  
                      </div>
                    </div>))} */}

                    
                    {q.type === 'Constructor Picks' && q.answer.map(a => (
                    <div>
                      <Constructor constructor={a} variant='input' 
                        expanded={isOpenAccordian(a)} 
                        handleExpansion={openAccordian} 
                        inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                        disabled={submission.state==='submitted'}></Constructor>
                    </div>
                    ))}

                    {/* {q.type === 'Constructor Picks' && q.answer.map(a => (
                    <div>
                      <div className={`dark-card selectable gap-0
                          ${a.userAnswer!==undefined ? 'selected' : ''} 
                          ${submission.state==='submitted' ? 'disabled' : ''}`}
                        
                        onClick={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 0)} >

                        <div className="answer background-image-contain"
                        style={{backgroundImage: `url(${a.logoUrl})`}}>
                          <div className='input'>
                            <input
                              type="radio" 
                              name={`User Answer Radio [${a.id}] 1`}
                              checked={a.userAnswer!==undefined} 
                              onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 0)} 
                              disabled={submission.state==='Submitted'} />                          
                          </div>
                          

                          <div className="constructor-details">
                            <h3>{`${a.name}`}</h3>
                          </div>
                          
                          <div className='stats'>
                            <Button variant='icon-outline' onClick={(e) => openAccordian(e, a)}>
                              <InsertChartOutlinedIcon fontSize='small' />
                            </Button>
                          </div>
                        </div>
                        
                        <div className={`stats-accordian ${isOpenAccordian(a) ? 'open' : ''}`}
                    
                        style={a.accentColor !== undefined && a.accentColor !== '' ? {
                          backgroundColor: `${a.accentColor}`,
                            boxShadow: 'inset rgba(0,0,0,1) 0 0 50px -2px'} : {}}>

                          <div className='dark-card'>
                            <h4>Last Race:</h4>
                            <p>
                              Pit Stops: {a.lastStats.pitStopCount}<br /> 
                              Points: {a.lastStats.points}
                            </p>
                          </div>

                          <div className='dark-card'>
                            <h4>This Race:</h4>
                            <p>
                              Pit Stops: {a.currentStats.pitStopCount}<br /> 
                              Points: {a.currentStats.points}
                            </p>
                          </div>

                          {a.driver.map(d => (
                            <div className='dark-card'>
                              <div className="driver-answer">
                                <img className="answer-image small" src={d.profilePicUrl} alt="answer-img" />
                                <div>
                                  <h4>{d.lastName}</h4>
                                  <p>Starting: P{d.startPosition}</p>
                                </div>
                              </div>
                            </div>  
                          ))}
                        </div>

                      </div>

                    </div>))} */}



                  {q.type === 'Bonus Radio' && q.answer.map(a => (
                  <div>
                    <div className={`dark-card selectable gap-0 
                      ${a.imageUrl !== undefined && a.imageUrl !== '' && 'background-image-cover'}
                      ${a.userAnswer!==undefined ? 'selected' : ''} 
                      ${submission.state==='submitted' ? 'disabled' : ''}`}
                    
                      style={a.imageUrl !== undefined && a.imageUrl !== '' ? {backgroundImage: `linear-gradient(to bottom, 
                        rgba(0, 0, 0, 0) 30%, 
                        var(--dark-4)), 
                        url(${a.imageUrl})
                        `,
                        flexShrink: 0,
                        justifyContent: 'flex-end'} : {}}
                      
                      onClick={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 0)} >

                      <div className='answer'>
                        <div className='input'>
                          <input
                              type="radio"
                              checked={a.userAnswer!==undefined} 
                              onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 0)} 
                              disabled={submission.state==='Submitted'} />
                        </div>
                        
                        <div className='bonus-radio-details'>
                          <div className="image-text">{a.text}</div>

                          {a.description !== undefined && a.description !== '' && (
                          <Button variant="icon" onClick={(e) => showTooltip(e, a.text, a.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>))}

                    
                  {q.type === 'Podium Picks' && q.answer.map(a => (
                  <div>
                    <div className="podium-picks-answer">
                      <img className="answer-image" src={a.profilePicUrl} alt="answer-img" />

                      <div className="driver-name">
                        <h4>{`${a.firstName} ${a.lastName}`}</h4>
                        <div className='flex align-items-center' style={{gap: '4px'}}>
                          <img className='constructor-logo-inline' src={a.constructorLogoUrl} alt={a.constructorName}></img>
                          <div>{`${a.constructorName}`}</div>
                        </div>
                      </div>

                      <div className="podium-picks-selections">
                        <input
                          type="radio" 
                          name={`User Answer Radio [${a.id}] 1`}
                          checked={a.userAnswer===1} 
                          onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 1)} 
                          disabled={submission.state==='Submitted'} />
                        <input
                          type="radio" 
                          name={`User Answer Radio [${a.id}] 2`}
                          checked={a.userAnswer===2} 
                          onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 2)} 
                          disabled={submission.state==='Submitted'} />
                        <input
                          type="radio" 
                          name={`User Answer Radio [${a.id}] 3`}
                          checked={a.userAnswer===3} 
                          onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 3)} 
                          disabled={submission.state==='Submitted'} />
                      </div>
                    </div>
                  </div>))}

                </>)}
              </>))
              )}
            </div>
            ))
          )}
          </>):(<></>
          )}
        {/* </div> */}
      </form>
    </div>
  );
}

export default Submission;