import React, { useState, useEffect } from 'react';
import './Countdown.css';

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        months: Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
        days: Math.floor((difference / (1000 * 60 * 60 * 24)) % 30),
      };
    }
    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
    // const timer = setTimeout(() => {
    //   setTimeLeft(calculateTimeLeft());
    // }, 1000);

    // return () => clearTimeout(timer);
  }, []);

  return (
    <div className="highlight-event" 
      style={{backgroundImage: `linear-gradient(to bottom, 
        rgba(0, 0, 0, 0.4) 70%, 
        rgba(14, 14, 14, 0.9) 80%), 
      url('https://wieck-honda-production.s3.amazonaws.com/photos/0c311ee1f3497eb2a8fdf1acf3dbb9f7c8c41757/preview-928x522.jpg')`}}>
        <div className='count-box-container'>
            <div className='count-box'>
                <div className='number'>{timeLeft.months}</div>
                <div>{(timeLeft.months>1) ? 'Months' : 'Month'}</div>
            </div>
            <div className='count-box'>
                <div className='number'>{timeLeft.days + 1}</div>
                <div>{(timeLeft.days + 1>1) ? 'Days' : 'Day'}</div>
            </div>
        </div>
        <h4 style={{paddingTop: '1em', textAlign: 'center'}}>2024 season starts in <b>Bahrain</b>, March 1st! 🎉</h4>
    </div>
  );
}

export default Countdown;
