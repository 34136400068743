import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button/Button';
import Loader from '../../components/loader/loader';
import {DropdownButton} from '../../components/DropdownButton/DropdownButton';
import PlaceGlyph from '../../components/PlaceGlyph/PlaceGlyph';
import { LineChart } from '@mui/x-charts/LineChart';
import UserChip from '../../components/UserChip/UserChip';
// import HomeIcon from '@mui/icons-material/Home';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import './leaderboard.css'

function Leaderboard({user}) {
    const navigate = useNavigate();
    const [year, setYear] = useState(2024);
    const [yearOptions, setYearOptions] = useState([2024, 2023]);
    const [leaderboard, setLeaderboard] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [loader, setLoader] = useState({show: false, text: ''});
    const [xLabels, setXLabels] = useState([]);
    const [plot, setPlot] = useState();

    const fetchLeaderboardData = (season) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: user.id,
          season: season
        })
    };

      setLoader({show: true, text: 'Loading Leaderboard...'});
      fetch(apiUrl + "/app/leaderboard", requestOptions)
        .then(response => response.json())
        .then(data => {
          setLeaderboard(data);


          setXLabels(data[0].submissions.map(s => s.round));
          let plotData = [];

          data.forEach(u => {
            plotData.push(
                {
                    label: u.firstName + ' ' + u.lastName,
                    data: u.submissions.map(s => s.cumulativePoints),
                    type: 'line',
                }
            );
          });
          setPlot(plotData);

          setFetchedData(true);
          setLoader({show: false});
        })
        .catch((err) => console.log(err));
    }

  
    useEffect(() => {
        if(user.id === null) {
            navigate(`/`);
        } else {
            console.log('useEffect Leaderboard: ', leaderboard);
            if(!fetchedData) {fetchLeaderboardData(year);}
        }
    }, [leaderboard])
    

    const goBack = () => {navigate(-1);}

    const handleYearChange = (selectedYear) => {
      setYear(selectedYear);
      fetchLeaderboardData(selectedYear);
    }

    return (
        <div className="page-container">
            <Loader config={loader}></Loader>


            <div className="page-header">
                <div>
                    <Button 
                        variant="link" 
                        onClick={(e) => goBack(e)}>
                        <NavigateBeforeIcon fontSize="small" />
                        Back
                    </Button>
                </div>
                <h2>Leaderboard</h2>
                <div className="flex justify-content-end">
                    <DropdownButton 
                    options={yearOptions}
                    value={year}
                    clickFunction={handleYearChange}
                    >
                    </DropdownButton>
                </div>
            </div>


            <div className="dark-card scrollable margin-inline-auto padding-0">
                {leaderboard.length > 0 && (
                <table className="table">
                    <thead style={{backgroundColor: 'var(--dark-3)'}}>
                        <tr>
                            {/* <th>+/-</th> */}
                            <th>Place</th>
                            <th>Name</th>
                            <th>Points</th>
                            {/* <th>Behind</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboard.map(row => (
                        <tr>
                            <td><PlaceGlyph place={row.place} /></td>
                            <td style={{maxWidth: '200px'}}>
                                <UserChip user={row.user}></UserChip>
                            </td>
                            <td>{row.totalPoints}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                )}
            </div>

            {/* {plot !== undefined && (
                <LineChart
                    width={500}
                    height={300}
                    series={plot}
                    xAxis={[{ scaleType: 'point', data: xLabels }]}
                    legend={{
                        direction: "column",
                        position: {
                          vertical: "middle",
                          horizontal: "right"
                        }
                      }}
                    />
            )} */}
        </div>
    )
}

export default Leaderboard;