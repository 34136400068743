import React from 'react'

function About() {
    return (
    <div className="page-container">
        <div className='dark-card margin-inline-auto' style={{marginTop: '1em'}}>
            <h1> Welcome! </h1>

            <p>
                Welcome to the 2024 F1 Formula Fantasy league! This is a casual fantasy style league where there will be a short selection of questions to answer every Grand Prix weekend. Correct picks get points which accumulate per person over the season. The Leaderboard will show the current standings of everyone and how many rounds to go. 


                <br />
                <br />
                Good Luck, and Lets Go Car Racing!
            </p> 

            <br />
            
            <h2>Formula Fantasy Team</h2>
            <p>
                Charlotte Gill<br />
                Sam Dubin<br />
                Mitch Buenaventura
            </p>
        </div>
    </div>
    )
}

export default About;