import React from 'react';
import './Tabs.css';

// Usage: 
// <TabGroup>
//   <Tab>One</Tab>
//   <Tab>Two</Tab>
//   <Tab>Three</Tab>
// </TabGroup>


function Tab({className, onClick, activeTab, ...attributes}) {
    return (
        <div className={`tab ${activeTab === attributes.label ? "active" : ""} ${className}`} {...attributes}
        onClick={e => onClick(e, attributes.label)}>
            {attributes.label}
        </div>
    )
}

export default Tab;