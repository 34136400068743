import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './DropdownButton.css';

// Usage: <Dropdown variant="chip" color="primary"></Dropdown>
// Variant:
//      (undefined)
//      primary
//      accent
//      warn

function DropdownButton({options, value, className, clickFunction}) {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const selectOption = (selectedOption) => {
        clickFunction(selectedOption);
        toggleMenu();
    };

    return (
        <div className='ff-dropdown-container'>
            <div className='ff-dropdown' onClick={() => toggleMenu()}>
                <div>{value}</div>
                
                <ExpandMoreIcon fontSize="small"/>
            </div>
            <div className={`ff-dropdown-menu ${showMenu ? `show` : ``}`}>
                {options.map((option) => (
                <div className={`ff-dropdown-item`} onClick={() => selectOption(option)}>
                    {option}
                </div>

                ))}
            </div>
        </div>
    )
}

export {DropdownButton};