import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Loader from '../../components/loader/loader';
import FormConfigModal from './FormConfigModal';
import SubmissionConfigModal from '../submission/SubmissionConfigModal';

import './form.css'

function Form({user}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  
  const [fetchedData, setFetchedData] = useState(false);
  const [deleteModal, setDeleteModal] = useState({show: false, header: '', body: '', type: '', id: 0});
  const [questionModal, setQuestionModal] = useState({show: false, sectionId: 0, afterQuestion: 0});
  const [formConfigModal, setFormConfigModal] = useState({show: false, formId: 0, formState: 0, formSubmissionDeadline: new Date()});  
  const [submissionConfigModal, setsubmissionConfigModal] = useState({show: false, submissionId: 0, submissionState: 0});

  const [loader, setLoader] = useState({show: false, text: ''});
  const [showSettings, setShowSettings] = useState(JSON.parse(localStorage.getItem("showAdminFormSettings")));

  const [event, setEvent] = useState({ name: ''});
  const [form, setForm] = useState({section: [{question: {}}]});
  const [submission, setSubmission] = useState({ id: null });
  
  const [formOptions, setFormOptions] = useState(
    { questionType: [],
      answerType: []}
  );

  useEffect(() => {
    console.log('useEffect Form: ', form);

    if(user.id === null || !user.isAdmin) {
        navigate(`/`);
    } else {
      if(!fetchedData) {
        fetchFormData();
      }
    }
  }, [form])

  const fetchFormData = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: user.id,
          formId: parseInt(searchParams.get('id'))
        })
    };

    setLoader({show: true, text: 'Loading Form...'});
    fetch(apiUrl + `/app/form`, requestOptions)
      .then(response => response.json())
      .then(data => {
          setFetchedData(true);
          setEvent(data.event);
          setFormOptions(data.options);

          if(data.submission !== undefined) {
            setSubmission(data.submission);
          }

          setForm({
            ...data.form,
            section: data.section
          });
          setLoader({show: false});
  })
      .catch((err) => console.log(err));
  }

  const goHome = () => {
    navigate(`/`); 
  }
  
  const goToSubmission = () => {
    console.log('goToSubmission',submission.id);
    if(submission.id === undefined || submission.id === null) {
      createSubmission(form.id);
    } else {
      navigate(`/event/submission/?id=${submission.id}`);
    }    
  }
  
  const goToReview = () => {
    if(submission.id === null) {
      createSubmission(form.id);
    } else {
      navigate(`/event/submission/review/?id=${submission.id}`);
    }    
  }

  const goToResponses = () => {
    navigate(`/event/form/responses/?id=${form.id}`);    
  }

  const toggleFormSettings = () => {
    localStorage.setItem("showAdminFormSettings", !showSettings);
    console.log('showAdminFormSettings changed: ', localStorage.getItem("showAdminFormSettings"));
    setShowSettings(!showSettings);
  }


  const openDeleteModal = (type, id) => {
    let modalData = {
      show: true,
      header: `Delete ${type}?`,
      body: `You are about to delete this ${type} and remove it from view. Are you sure?`,
      type: type,
      id: id
    }
    
    setDeleteModal(modalData);
  }

  const closeDeleteModal = (action) => {
    if (action !== undefined && action === 'Delete') {
      

      let apiEndpoint = '';
      let apiBody = '';

    
      if (deleteModal.type === 'Section') {
        apiEndpoint = '/app/section/delete';
        apiBody = JSON.stringify({
          sectionId: deleteModal.id
        });
      } else if (deleteModal.type === 'Question') {
        apiEndpoint = '/app/question/delete';
        apiBody = JSON.stringify({
          questionId: deleteModal.id
        });

      } else if (deleteModal.type === 'Answer') {
        apiEndpoint = '/app/answer/delete';
        apiBody = JSON.stringify({
          answerId: deleteModal.id
        });

      } else {

      }
    

      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;      
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: apiBody
      };

      setLoader({show: true, text: `Deleting ${deleteModal.type}...`});
      fetch(apiUrl + apiEndpoint, requestOptions)
        .then(response => response.json())
        .then(() => {
          fetchFormData();
          
          let modalData = {
            show: false,
            header: ``,
            body: ``,
            type: ``,
            id: 0
          }
          setDeleteModal(modalData);
          setLoader({show: false});
      })
        .catch((err) => console.log(err));

    } else {
          
      let modalData = {
        show: false,
        header: ``,
        body: ``,
        type: ``,
        id: 0
      }
      setDeleteModal(modalData);
    } 
  }

  const createSubmission = (formId) => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          formId: formId, 
          userId: user.id 
        })
    };

    setLoader({show: true, text: 'Creating Submission...'});
    fetch(apiUrl + "/app/submission/create", requestOptions)
      .then(response => response.json())
      .then(data => {
        let submissionId = data.id;
        setLoader({show: false});
        navigate(`/event/submission/?id=${submissionId}`);
  })
      .catch((err) => console.log(err));
  }


  const addFormSection = (formId, afterSection) => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    setLoader({show: true, text: 'Adding section...'});

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          formId: formId, 
          title: null,
          description: null, 
          showDescription: false, 
          afterSection: afterSection
        })
    };

    fetch(apiUrl + "/app/section/create", requestOptions)
      .then(response => response.json())
      .then(() => {
        fetchFormData();
    })
      .catch((err) => console.log(err));
  }


  const openQuestionModal = (sectionId, afterQuestion) => {

    setQuestionModal({show: true, sectionId: sectionId, afterQuestion: afterQuestion});
  }

  const closeQuestionModal = (action, type) => {
    if (action !== undefined && action === 'Add') {      

      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
      let endpoint = '';
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sectionId: questionModal.sectionId, 
          text: '',
          description: '', 
          showDescription: false, 
          type: type,
          afterQuestion: questionModal.afterQuestion
        })
      };

      if (type === 'Driver Picks') {
        endpoint = '/app/question/createDriverPicks';
        requestOptions.body = JSON.stringify({
              sectionId: questionModal.sectionId, 
              afterQuestion: questionModal.afterQuestion,
              createdBy: user.id
            });

      } else if (type === 'Constructor Picks') {
        endpoint = '/app/question/createConstructorPicks';
        requestOptions.body = JSON.stringify({
              sectionId: questionModal.sectionId, 
              afterQuestion: questionModal.afterQuestion,
              createdBy: user.id
            });

      } else if (type === 'Podium Picks') {
        endpoint = '/app/question/createPodium';
        requestOptions.body = JSON.stringify({
              sectionId: questionModal.sectionId, 
              afterQuestion: questionModal.afterQuestion,
              createdBy: user.id
            });

      } else if (type === 'Bonus Radio') {
        endpoint = '/app/question/create';
        requestOptions.body = JSON.stringify({
              sectionId: questionModal.sectionId, 
              text: '',
              description: '', 
              showDescription: false, 
              type: type,
              afterQuestion: questionModal.afterQuestion
            });
      }

      setLoader({show: true, text: 'Adding Question...'});
      fetch(apiUrl + endpoint, requestOptions)
        .then(response => response.json())
        .then(() => {
          fetchFormData();
          setQuestionModal({show: false, sectionId: 0, afterQuestion: 0});
          setLoader({show: false});
      })
        .catch((err) => console.log(err));

    } else {
      setQuestionModal({show: false, sectionId: 0, afterQuestion: 0});
    }
  }


  const addFormAnswer = (questionId, afterAnswer) => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    setLoader({show: true, text: 'Adding answer...'});

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          questionId: questionId, 
          text: '',
          description: '', 
          type: 'Selectable',
          imageUrl: '', 
          afterAnswer: afterAnswer,
          createdBy: user.id
        })
    };

    fetch(apiUrl + "/app/answer/create", requestOptions)
      .then(response => response.json())
      .then(() => {
        fetchFormData();
    })
      .catch((err) => console.log(err));
  }


  const handleSectionChange = (event, sectionId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedForm = JSON.parse(JSON.stringify(form)); 
    console.log(`handleSectionChange [${event.target.name}] [${sectionId}]`);

    for (const [i, s] of Object.entries(updatedForm.section)) {
      if (s.id === sectionId) {
        if (event.target.name === 'Section Title') {
          updatedForm.section[i].title = event.target.value;
          console.log(`Updated ${event.target.name} [${s.id}]`);
          
        } else if (event.target.name === 'Section Description') {
          updatedForm.section[i].description = event.target.value;
          console.log(`Updated ${event.target.name} [${s.id}]`);

        } else if (event.target.name === 'Section Description Toggle') {
          updatedForm.section[i].showDescription = event.target.checked;
          console.log(`Updated ${event.target.name} [${s.id}]`);

        }
      }
      setForm(updatedForm);
    }     
  }

  const handleSectionBlur = (sectionId) => {
    console.log(`handleSectionBlur [${sectionId}]`);

    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    for (const [i, s] of Object.entries(form.section)) {
      if (s.id === sectionId) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              sectionId: sectionId, 
              title: form.section[i].title,
              description: form.section[i].description, 
              showDescription: form.section[i].showDescription
            })
        };

        fetch(apiUrl + "/app/section/update", requestOptions)
          .then(response => response.json())
          .catch((err) => console.log(err));
      }
    }
  }

  const handleSectionShowDescriptionChange = (sectionId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedForm = JSON.parse(JSON.stringify(form)); 
    console.log(`handleSectionShowDescriptionChange [${sectionId}]`);

    for (const [i, s] of Object.entries(updatedForm.section)) {
      if (s.id === sectionId) {
          updatedForm.section[i].showDescription = !updatedForm.section[i].showDescription;
          console.log(`Updated showDescription [${s.id}]`);
      }
      handleSectionBlur(sectionId);
      setForm(updatedForm);
    }
  }


  const handleQuestionChange = (event, sectionId, questionId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedForm = JSON.parse(JSON.stringify(form)); 
    console.log(`handleQuestionChange [${event.target.name}] [${sectionId}] [${questionId}]`);

    for (const [i, s] of Object.entries(updatedForm.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(updatedForm.section[i].question)) {
          if (q.id === questionId) {
            if (event.target.name === 'Question Text') {
              updatedForm.section[i].question[j].text = event.target.value;
              console.log(`Updated ${event.target.name} [${q.id}]`);
              
            } else if (event.target.name === 'Question Description') {
              updatedForm.section[i].question[j].description = event.target.value;
              console.log(`Updated ${event.target.name} [${q.id}]`);

            } else if (event.target.name === 'Question Description Toggle') {
              updatedForm.section[i].question[j].showDescription = event.target.checked;
              console.log(`Updated ${event.target.name} [${q.id}] (${event.target.checked})`);

            } else {
              console.log(`Unknown input name: ${event.target.name} [${q.id}]`);
            }
          }
        }
      }
      setForm(updatedForm);

      //check box types of input onBlur does not trigger, so need to manually do it onChange
      if (event.target.name === 'Question Description Toggle') {
        handleQuestionBlur(event, sectionId, questionId);
      }
    }     
  }

  const handleQuestionBlur = (sectionId, questionId) => {
    console.log(`handleQuestionBlur [${sectionId}] [${questionId}]`);

    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    for (const [i, s] of Object.entries(form.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(form.section[i].question)) {
          if (q.id === questionId) {
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                questionId: questionId, 
                text: form.section[i].question[j].text,
                description: form.section[i].question[j].description, 
                showDescription: form.section[i].question[j].showDescription, 
                type: form.section[i].question[j].type
              })
            };

            fetch(apiUrl + "/app/question/update", requestOptions)
              .then(response => response.json())
            //   .then(() => {
            //     fetchFormData();
            // })
              .catch((err) => console.log(err));

          }
        }
      }
    }
  }
  

  const handleQuestionShowDescriptionChange = (sectionId, questionId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedForm = JSON.parse(JSON.stringify(form)); 
    console.log(`handleQuestionShowDescriptionChange [${sectionId}] [${questionId}]`);

    for (const [i, s] of Object.entries(updatedForm.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(updatedForm.section[i].question)) {
          if (q.id === questionId) {
            updatedForm.section[i].question[j].showDescription = !updatedForm.section[i].question[j].showDescription;
            console.log(`Updated showDescription [${q.id}]`);
          }
        }
      }
      handleQuestionBlur(sectionId, questionId);
      setForm(updatedForm);
    }     
  }

  const handleAnswerChange = (event, sectionId, questionId, answerId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedForm = JSON.parse(JSON.stringify(form)); 
    console.log(`handleAnswerChange [${event.target.name}] [${sectionId}] [${questionId}] [${answerId}]`);

    for (const [i, s] of Object.entries(updatedForm.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(updatedForm.section[i].question)) {
          if (q.id === questionId) {
            for (const [k, a] of Object.entries(updatedForm.section[i].question[j].selectable)) {
              if (a.id === answerId) {
                if (event.target.name === 'Answer Text') {
                  updatedForm.section[i].question[j].selectable[k].text = event.target.value;
                  console.log(`Updated ${event.target.name} [${a.id}]`);
              
                } else if (event.target.name === 'Answer Description') {
                  updatedForm.section[i].question[j].selectable[k].description = event.target.value;
                  console.log(`Updated ${event.target.name} [${a.id}]`);
                  
                } else if (event.target.name === 'Answer Image Url') {
                  updatedForm.section[i].question[j].selectable[k].imageUrl = event.target.value;
                  console.log(`Updated ${event.target.name} [${a.id}]`);
                  
                } else {
                  console.log(`Unknown input name: ${event.target.name} [${a.id}]`);
                }
              }
            }
          }
        }
      }
      setForm(updatedForm);
    }     
  }

  const handleAnswerBlur = (sectionId, questionId, answerId) => {
    console.log(`handleAnswerBlur [${sectionId}] [${questionId}] [${answerId}]`);

    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    for (const [i, s] of Object.entries(form.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(form.section[i].question)) {
          if (q.id === questionId) {
            for (const [k, a] of Object.entries(form.section[i].question[j].selectable)) {
              if (a.id === answerId) {
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    answerId: answerId, 
                    text: form.section[i].question[j].selectable[k].text,
                    description: form.section[i].question[j].selectable[k].description,
                    imageUrl: form.section[i].question[j].selectable[k].imageUrl
                  })
                };
    
                fetch(apiUrl + "/app/answer/update", requestOptions)
                  .then(response => response.json())
                  .catch((err) => console.log(err));
    
              }
            }
          }
        }
      }
    }
  }


  const handleCorrectAnswerChange = (sectionId, questionId, answerId) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedForm = JSON.parse(JSON.stringify(form)); 

    console.log(`handleCorrectAnswerChange [${sectionId}] [${questionId}] [${answerId}]`);


    for (const [i, s] of Object.entries(form.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(form.section[i].question)) {
          if (q.id === questionId) {
            for (const [k, a] of Object.entries(form.section[i].question[j].selectable)) {
              if (a.id === answerId) {

                let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

                if (updatedForm.section[i].question[j].selectable[k].isCorrect) { 
                  setLoader({show: true, text: 'Unsetting correct answer...'}); 
                  const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({
                        questionId: questionId, 
                        answerId: answerId
                      })
                  };
              
                  fetch(apiUrl + "/app/correctAnswer/delete", requestOptions)
                    .then(response => response.json())
                    .then(() => {
                      updatedForm.section[i].question[j].selectable[k].isCorrect = 0;
                      setForm(updatedForm);
                      setLoader({show: false});
                  })
                    .catch((err) => console.log(err));

                } else {  
                  setLoader({show: true, text: 'Setting correct answer...'});
                  const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({
                        questionId: questionId, 
                        answerId: answerId
                      })
                  };
              
                  fetch(apiUrl + "/app/correctAnswer/create", requestOptions)
                    .then(response => response.json())
                    .then(() => {
                      updatedForm.section[i].question[j].selectable[k].isCorrect = 1;
                      setForm(updatedForm);
                      setLoader({show: false});
                  })
                    .catch((err) => console.log(err));
                }
              }
            }
          }
        }
      }
    }
  }
  
  const handleCorrectPodiumAnswerChange = (sectionId, questionId, answerId, place) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedForm = JSON.parse(JSON.stringify(form)); 

    console.log(`handleCorrectPodiumAnswerChange [${sectionId}] [${questionId}] [${answerId}] [${place}]`);


    for (const [i, s] of Object.entries(form.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(form.section[i].question)) {
          if (q.id === questionId) {
            for (const [k, a] of Object.entries(form.section[i].question[j].selectable)) {
              if (a.id === answerId) {
                updatedForm.section[i].question[j].selectable[k].isCorrect = place;
              } else if (a.isCorrect === place) {  
                updatedForm.section[i].question[j].selectable[k].isCorrect = 0;
              }
            }
          }
        }
      }
    }

    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    setLoader({show: true, text: 'Setting correct podium answer...'}); 
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          questionId: questionId, 
          answerId: answerId,
          place: place
        })
    };

    fetch(apiUrl + "/app/correctAnswer/podium", requestOptions)
      .then(response => response.json())
      .then(() => {
        setForm(updatedForm);
        setLoader({show: false});
    })
      .catch((err) => console.log(err));
  }
    
  const openFormConfigModal = () => {
    setFormConfigModal({
      show: true, 
      formId: form.id, 
      formState: form.state, 
      formSubmissionDeadline: form.submissionDeadline
    });
  }
  const closeFormConfigModal = (action) => {
    if (action !== undefined && action === 'Update') {      
      fetchFormData();    
    }
    
    setFormConfigModal({show: false, formId: 0, formState: 0});    
  }
    
  const openSubmissionConfigModal = () => {
    setsubmissionConfigModal({
      show: true, 
      submissionId: submission.id, 
      submissionState: submission.state
    });
  }
  const closeSubmissionConfigModal = (action) => {
    if (action !== undefined && action === 'Update') {      
      fetchFormData();    
    }
    
    setsubmissionConfigModal({show: false, submissionId: 0, submissionState: 0});    
  }


  return ( 
    <div className="page-container form-component">
      <Loader config={loader}></Loader>
      
      <FormConfigModal formConfig={formConfigModal} onHide={closeFormConfigModal}></FormConfigModal>
      <SubmissionConfigModal submissionConfig={submissionConfigModal} onHide={closeSubmissionConfigModal}></SubmissionConfigModal>

      <div className="flex justify-content-between">
        <ul className="breadcrumb">
          <li className="back-btn" onClick={goHome}>
            <HomeIcon fontSize="small"/>
            <span>Home</span>
          </li>
          <li>
            <span>{`Round ${event.round}`}</span>
          </li>
        </ul>
        {user.isAdmin && (<Button variant="icon" onClick={toggleFormSettings} ><SettingsIcon fontSize="small" /></Button>)}
      </div>

      
      {user.isAdmin && showSettings && (
      <div className="settings-container">
        <div className="label-adorner">
          {`{ eId: ${event.id}, fId: ${form.id}, fState: ${form.state}, subId: ${submission.id} }`}
        </div>
        <div className="flex flex-wrap">
          <Button variant="primary" disabled>Edit</Button>
          <Button variant="outline-light" onClick={goToSubmission}>Preview</Button>
          <Button variant="outline-light" onClick={goToReview}>Review</Button>
          <Button variant="outline-light" onClick={goToResponses}>Responses</Button>
          <Button variant="outline-light" onClick={openFormConfigModal}>Manage Form</Button>
          <Button variant="outline-light" onClick={openSubmissionConfigModal}>Manage Sub</Button>
        </div>
      </div>
      )}

      <h2>{event.name}</h2>

      <form className="form" onSubmit={e => {e.preventDefault();}}>
        
        <Modal show={deleteModal.show} onHide={closeDeleteModal} center="true">
          <h2>{deleteModal.header}</h2>
          <p>{deleteModal.body}</p>
          <div className="modal-footer">
            <Button variant="secondary" onClick={closeDeleteModal}>Cancel</Button>
            <Button variant="primary" onClick={() => closeDeleteModal('Delete')}>Delete</Button>
          </div>
        </Modal>
        
        <Modal show={questionModal.show} onHide={closeQuestionModal} center="true">
          <h2>Select a Question Type</h2>
          <p>
            
            { formOptions.questionType.length > 0 && formOptions.questionType[0].name !== undefined && (
              formOptions.questionType.map(t => (
                <Button variant="link" onClick={() => closeQuestionModal('Add', t.name)} key={t.name}>{t.name}</Button>
              ))
            )}
            
          </p>
          <div className="modal-footer">
            <Button variant="secondary" onClick={closeQuestionModal}>Cancel</Button>
          </div>
        </Modal>

        
        <div className="form-content">
          <div className="adder" onClick={() => addFormSection(form.id, null)} >
            <div className="adder-line">
              <div className="adder-label"><AddIcon fontSize="small" />Add Section</div>
            </div>
          </div>

          { form.section.length > 0 && form.section[0].id !== undefined && (
            form.section.map(s => (<>
                <div className="dark-card margin-inline-auto">
                  <div className="section-header">
                    <div className="label-bar">
                      <label>
                        <input 
                          className="input-section"
                          type="text" 
                          name="Section Title" 
                          value={s.title} 
                          placeholder="Section Title" 
                          onChange={(e) => handleSectionChange(e, s.id)} 
                          onBlur={() => handleSectionBlur(s.id)} />
                      </label>                      
                      <Button variant="icon-subtle" onClick={() => openDeleteModal('Section', s.id)}><DeleteForeverIcon fontSize="small" /></Button>
                    </div>                    

                    {s.showDescription && (
                    <textarea 
                      type="text" 
                      name="Section Description" 
                      value={s.description} 
                      placeholder="Description" 
                      onChange={(e) => handleSectionChange(e, s.id)}  
                      onBlur={() => handleSectionBlur(s.id)} />
                    )}

                    <div className="label-adorner">
                      <Button variant="link-subtle" onClick={() => handleSectionShowDescriptionChange(s.id)}>
                        {s.showDescription ? 'Hide Descrip.' : 'Show Descrip.'}</Button>
                      {`{ sId: ${s.id}, order: ${s.order} }`}
                    </div>
                  </div>
          

                  <div className="adder" onClick={() => openQuestionModal(s.id, null)}>
                    <div className="adder-line">
                      <div className="adder-label"><AddIcon fontSize="small" />Add Question</div>
                    </div>
                  </div>

                  { s.question.length > 0 && s.question[0].id !== undefined && (
                    s.question.map(q => (
                    <div className="question-container" key={`q${q.id}`}>
                      <div className="question">
                        <div className="label-bar">
                          <label>
                            <input 
                              className="input-question"
                              type="text" 
                              name="Question Text" 
                              value={q.text} 
                              placeholder="Question" 
                              onChange={(e) => handleQuestionChange(e, s.id, q.id)} 
                              onBlur={() => handleQuestionBlur(s.id, q.id)} />
                          </label>
                          <Button variant="icon-subtle" onClick={() => openDeleteModal('Question', q.id)} ><DeleteForeverIcon fontSize="small" /></Button>
                        </div>
        
                        {q.showDescription && (
                        <textarea
                          type="text" 
                          name="Question Description"
                          value={q.description} 
                          placeholder="Description" 
                          onChange={(e) => handleQuestionChange(e, s.id, q.id)} 
                          onBlur={() => handleQuestionBlur(s.id, q.id)} />
                        )}
                        
                        <div className="label-adorner">
                          <Button variant="link-subtle" onClick={() => handleQuestionShowDescriptionChange(s.id, q.id)}>
                            {q.showDescription ? 'Hide Descrip.' : 'Show Descrip.'}</Button>
                          {`{ qId: ${q.id}, order: ${q.order}, type: ${q.type} }`}
                        </div>
                      </div>

                      <div className="question-answer-container">

                      {q.type === 'Bonus Radio' && (
                      <div className="adder" onClick={() => addFormAnswer(q.id, null)} >
                        <div className="adder-line">
                            <div className="adder-label"><AddIcon fontSize="small" />Add Answer</div>
                        </div>
                      </div>
                      )}

                        { q.selectable.length > 0 && q.selectable[0].id !== undefined && (<>
                        
                          {q.type === 'Driver Picks' && q.selectable.map(a => (<>
                            <div className='answer' key={`a${a.id}`}>

                              <div className="label-adorner">
                                <div>
                                    {`{ aId: ${a.id}, order: ${a.order} }`}
                                </div>

                                <Button variant="icon-subtle" onClick={() => openDeleteModal('Answer', a.id)} ><DeleteForeverIcon fontSize="small" /></Button>
                              </div>

                              <div className='flex'>
                                <img className="answer-image" src={a.profilePicUrl} alt="answer-img" />

                                <div className="driver-name">
                                  <h4>{`${a.firstName} ${a.lastName}`}</h4>
                                  <div className='flex align-items-center' style={{gap: '4px'}}>
                                    <img className='constructor-logo-inline' src={a.constructorLogoUrl} alt={a.constructorName}></img>
                                    <div>{`${a.constructorName}`}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>))}
                          
                          {q.type === 'Constructor Picks' && q.selectable.map(a => (<>
                            <div className='answer' key={`a${a.id}`}>

                              <div className="label-adorner">
                                <div>
                                    {`{ aId: ${a.id}, order: ${a.order} }`}
                                </div>

                                <Button variant="icon-subtle" onClick={() => openDeleteModal('Answer', a.id)} ><DeleteForeverIcon fontSize="small" /></Button>
                              </div>

                              <div className='flex'>
                                <img className="answer-image" src={a.logoUrl} alt={a.name} />

                                <div className="driver-name">
                                  <h4>{`${a.name}`}</h4>
                                </div>
                              </div>
                            </div>
                          </>))}
                          
                          {q.type === 'Podium Picks' && q.selectable.map(a => (<>
                            <div className='answer' key={`a${a.id}`}>

                              <div className="label-adorner">
                                <div>
                                    {`{ aId: ${a.id}, order: ${a.order} }`}
                                </div>

                                <Button variant="link-subtle" onClick={() => handleCorrectPodiumAnswerChange(s.id, q.id, a.id, 1)}>
                                  {a.isCorrect===1 ? 'Unmark P1' : 'Mark P1'}</Button>
                                <Button variant="link-subtle" onClick={() => handleCorrectPodiumAnswerChange(s.id, q.id, a.id, 2)}>
                                  {a.isCorrect===2 ? 'Unmark P2' : 'Mark P2'}</Button>
                                <Button variant="link-subtle" onClick={() => handleCorrectPodiumAnswerChange(s.id, q.id, a.id, 3)}>
                                  {a.isCorrect===3 ? 'Unmark P3' : 'Mark P3'}</Button>

                                <div>
                                  {a.isCorrect !== undefined && a.isCorrect && (`⭐(${a.isCorrect})`)}
                                </div>

                                <Button variant="icon-subtle" onClick={() => openDeleteModal('Answer', a.id)} ><DeleteForeverIcon fontSize="small" /></Button>
                              </div>

                              <div className='flex'>
                                <img className="answer-image" src={a.profilePicUrl} alt="answer-img" />

                                <div className="driver-name">
                                  <h4>{`${a.firstName} ${a.lastName}`}</h4>
                                  <div className='flex align-items-center' style={{gap: '4px'}}>
                                    <img className='constructor-logo-inline' src={a.constructorLogoUrl} alt={a.constructorName}></img>
                                    <div>{`${a.constructorName}`}</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="adder" onClick={() => addFormAnswer(q.id, a.id)} >
                              <div className="adder-line">
                                  <div className="adder-label"><AddIcon fontSize="small" /> Add Answer</div>
                              </div>
                            </div>
                          </>))}



                          {q.type==='Bonus Radio' && q.selectable.map(a => (<>
                            <div className='answer' key={`a${a.id}`}>
                              <div className='label-adorner'>
                                <div>{`{ aId: ${a.id}, order: ${a.order} }`}</div>

                                <Button variant="link-subtle" onClick={() => handleCorrectAnswerChange(s.id, q.id, a.id)}>
                                  {a.isCorrect ? 'Mark as Incorrect' : 'Mark as Correct'}
                                </Button>                                
                                
                                <div>
                                  {a.isCorrect!==undefined && (`⭐`)}
                                </div>
                                
                                <Button variant="icon-subtle" onClick={() => openDeleteModal('Answer', a.id)} ><DeleteForeverIcon fontSize="small" /></Button>
                              </div>

                              <label>
                                <input 
                                  className="input-answer"
                                  type="text" 
                                  name="Answer Text" 
                                  value={a.text} 
                                  placeholder="Answer" 
                                  onChange={(e) => handleAnswerChange(e, s.id, q.id, a.id)} 
                                  onBlur={() => handleAnswerBlur(s.id, q.id, a.id)} />
                              </label>
                                
                              <textarea
                                  type="text" 
                                  name="Answer Description" 
                                  value={a.description} 
                                  placeholder="Description" 
                                  onChange={(e) => handleAnswerChange(e, s.id, q.id, a.id)} 
                                  onBlur={() => handleAnswerBlur(s.id, q.id, a.id)} />

                              <input 
                                type="text" 
                                name="Answer Image Url" 
                                value={a.imageUrl} 
                                placeholder="Image Url" 
                                onChange={(e) => handleAnswerChange(e, s.id, q.id, a.id)} 
                                onBlur={() => handleAnswerBlur(s.id, q.id, a.id)} />
                                
                              {a.imageUrl !== undefined && a.imageUrl !== '' && (
                              <img className="answer-image" src={a.imageUrl} alt="answer-img" />
                              )}
                            </div>

                            <div className="adder" onClick={() => addFormAnswer(q.id, a.id)} >
                              <div className="adder-line">
                                <div className="adder-label"><AddIcon fontSize="small" /> Add Answer</div>
                              </div>
                            </div>
                          </>))}

                        </>)}
                      </div>
                          
                      <div className="adder" onClick={() => openQuestionModal(s.id, q.id)}>
                        <div className="adder-line">
                          <div className="adder-label"><AddIcon fontSize="small" />Add Question</div>
                        </div>
                      </div>
                    </div>
                    ))
                  )}
                </div>
                      
                <div className="adder" onClick={() => addFormSection(form.id, s.id)}>
                  <div className="adder-line">
                    <div className="adder-label"><AddIcon fontSize="small" />Add Section</div>
                  </div>
                </div>
            </>))
          )}
        </div>
      </form>
    </div>
  );
}

export default Form;