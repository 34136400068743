import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";

import Modal from '../components/Modal/Modal';
import EmailVerified from "./Auth/EmailVerified";
// import CustomizeUserChip from "../components/UserChip/UserChip";
import Schedule from './schedule/schedule'

function Home({user}) {

  const navigate = useNavigate();
  const {pathname} = useLocation();

  useEffect(() => {
    if(pathname==='/complete-verification') {
      openEmailVerified();
    }
    navigate('/');

  }, [ pathname ]);

  const [showEmailVerified, setShowEmailVerified] = useState(false);
  const openEmailVerified = () => setShowEmailVerified(true);
  const closeEmailVerified = (action) => {
      setShowEmailVerified(false);
  }

  return (<>
  
    <Modal show={showEmailVerified} onHide={closeEmailVerified} closeOnOverlayClick={true}>
        <EmailVerified onClose={closeEmailVerified}></EmailVerified>
    </Modal>

    <Schedule user={user}></Schedule>
    
  </>)
}

export default Home;