import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import UserChip from '../../components/UserChip/UserChip';
import PlaceGlyph from '../../components/PlaceGlyph/PlaceGlyph';
import SettingsIcon from '@mui/icons-material/Settings';
import Loader from '../../components/loader/loader';
import './PointsBreakdown.css';

function PointsBreakdown({user, formId}) {
    const navigate = useNavigate();
    const [fetchedData,     setFetchedData] = useState(false);
    const [pointsModal, setPointsModal] = useState({show: false, submissionId: 0, adjustmentPoints: 0});
    
    const [results, setResults] = useState([]);

    const [loader, setLoader] = useState({show: false, text: ''});

  

    const fetchResultsData = useCallback(() => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            formId: formId
          })
      };

      setLoader({show: true, text: 'Loading results data...'});
      fetch(apiUrl + `/app/results/pointsBreakdown`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setResults(data);

            console.log(`setResults: `, data);

            setFetchedData(true);
            setLoader({show: false});
      })
        .catch((err) => {
            console.log(err);   
            setLoader({show: false});
        });
    }, [formId] )
    


    useEffect(() => {
        console.log('useEffect results: ', results);

        if(user.id === null) {
            navigate(`/`);
        } else {
            if(!fetchedData) {fetchResultsData();}
        }
    }, [results]);
    

    

    const goToLeaderboard = () => {
        navigate(`/leaderboard`);
    }

    
    const openPointsModal = (submissionId, adjustmentPoints) => {
        setPointsModal({show: true, submissionId: submissionId, adjustmentPoints: adjustmentPoints});
    }

    const closePointsModal = (action) => {
        if (action !== undefined && action === 'Set') {      

        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                submissionId: pointsModal.submissionId, 
                adjustmentPoints: pointsModal.adjustmentPoints
            })
        };

        setLoader({show: true, text: 'Setting points...'});
        fetch(apiUrl + "/app/submission/setPoints", requestOptions)
            .then(response => response.json())
            .then(() => {
            setPointsModal({show: false, submissionId: 0, adjustmentPoints: 0});
            setLoader({show: false});
            fetchResultsData();
        })
            .catch((err) => console.log(err));

        } else {
            setPointsModal({show: false, submissionId: 0, adjustmentPoints: 0});
        }
    }
    
        

    const handlePointsChange = (e) => {

        setPointsModal({show: true, submissionId: pointsModal.submissionId, adjustmentPoints: parseInt(e.target.value)});
    }


    return (
        <div>
            <Loader config={loader}></Loader>
      
            <Modal show={pointsModal.show} onHide={closePointsModal} closeOnOverlayClick={true}>
                <h2>Set Points</h2>
                <p>
                    <form onSubmit={e => {e.preventDefault();}}>
                        <label for="quantity">Additional Points:</label>
                        <input
                            type="number"
                            name="quantity"
                            min="0" max="50"
                            value={pointsModal.adjustmentPoints}
                            onChange={handlePointsChange} />
                    </form>
                </p>
                <div className="modal-footer">
                    <Button variant="secondary" onClick={closePointsModal}>Cancel</Button>
                    <Button variant="primary" onClick={() => closePointsModal('Set')}>Set</Button>
                </div>
            </Modal>
            
            {results.length > 0 && (
            <table className="table">
                <thead style={{backgroundColor: 'var(--dark-2)'}}>
                    <tr>
                        <th>Place</th>
                        <th>Name</th>
                        <th>Points</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map(row => (
                    <tr className={row.user.id===user.id ? 'highlighted-user' : ''}>
                        <td><PlaceGlyph place={row.place} /></td>
                        <td style={{maxWidth: '200px'}}>
                            <UserChip user={row.user}></UserChip>
                        </td>
                        <td className="flex justify-content-between align-items-center">
                            <div>{row.totalPoints}</div>
                            {user.isAdmin && (
                            <Button variant="icon" onClick={() => openPointsModal(row.id, row.adjustmentPoints)}>
                                <SettingsIcon fontSize="small" style={row.adjustmentPoints>0 ? {color: 'gold'} : {}} />
                            </Button>
                            )}
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            )}
        </div>
    )
}

export default PointsBreakdown;