import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from '../../components/Button/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from '../../components/Modal/Modal';
import Chip from '../../components/Chip/Chip';
import Loader from '../../components/loader/loader';
import Driver from '../../components/Driver/Driver';
import Constructor from '../../components/Constructor/Constructor';
import FormConfigModal from '../form/FormConfigModal';
import SubmissionConfigModal from './SubmissionConfigModal';
import Linkify from 'react-linkify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

import './review.css';

function Review({user}) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [fetchedData, setFetchedData] = useState(false);
    const [loader, setLoader] = useState({show: false,text: ''});

    const [formConfigModal, setFormConfigModal] = useState({show: false, formId: 0, formState: 0});  
    const [submissionConfigModal, setsubmissionConfigModal] = useState({show: false, submissionId: 0, submissionState: 0});
    const [statModal, setStatModal] = useState({show: false, eventId: 0, type: null, refId: 0});
    const [tooltip, setTooltip] = useState({show: false, header: '', body: ''});
    const [submitModalShow, setSubmitModalShow] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSettings, setShowSettings] = useState(JSON.parse(localStorage.getItem("showAdminFormSettings")));
    
    const [event, setEvent] = useState({ name: '', datetime: ''});
    const [form, setForm] = useState({id: null});
    const [submission, setSubmission] = useState({id: null, sections: [{questions: {}}]});
    
    useEffect(() => {
        console.log('useEffect Submission: ', submission);
        console.log('showAdminFormSettings: ', showSettings);

        if(user.id === null) {
            navigate(`/`);
        } else {
        if(!fetchedData) {        
            fetchSubmissionData();
        }
        }
    }, [submission])
  

    const fetchSubmissionData = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userId: user.id
            })
        };
    
        setLoader({show: true, text: 'Loading submission review...'});
        fetch(apiUrl + `/app/submission/review?id=${searchParams.get('id')}`, requestOptions)
          .then(response => response.json())
          .then(data => {
              setFetchedData(true);

              let revisedEventData = {
                ...data.event,
                datetime: new Date(data.event.date)
              };
              setEvent(revisedEventData);
              setForm(data.form);
              setSubmission({
                ...data.submission,
                sections: data.sections
              });
              setLoader({show: false});
      })
          .catch((err) => console.log(err));
      }


      const goBack = () => {navigate(-1);}
      // const goHome = () => {
      //   navigate(`/`); 
      // }
      
      const goToForm = () => {
        navigate(`/event/form/?id=${form.id}`);
      }
      
      const goToEvent = () => {        
        navigate(`/event/?event=${event.id}`);
      }

      const goToSubmission = (e) => {
          e.stopPropagation();

          navigate(`/event/submission/?id=${searchParams.get('id')}`);
      }
      
      const goToResponses = () => {
        navigate(`/event/form/responses/?id=${form.id}`);    
      }

      const toggleFormSettings = () => {
        localStorage.setItem("showAdminFormSettings", !showSettings);
        console.log('showAdminFormSettings changed: ', localStorage.getItem("showAdminFormSettings"));
        setShowSettings(!showSettings);
      }

      const openStatModal = (e, type, refId) => {
        e.stopPropagation();
    
        setStatModal({
          show: true,
          eventId: event.id,
          type: type,
          refId: refId
        })
      }
    
      const closeStatModal = () => {
        setStatModal({
          show: false,
          eventId: event.id,
          type: null,
          refId: 0
        })
      }
    
      const openSubmitModal = () => {
        setSubmitModalShow(true);
      }
    
      const closeSubmitModal = (action) => {    
        if (action !== undefined && action === 'Submit') {
          setLoader({show: true, text: 'Submitting...'});          
    
          let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;      
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                submissionId: submission.id,
                state: 'Submitted'
              })
          };
    
          setIsSubmitting(true);
          
          fetch(apiUrl + `/app/submission/update`, requestOptions)
            .then(response => response.json())
            .then(() => {
              fetchSubmissionData();
              setIsSubmitting(false);
              setSubmitModalShow(false);
              setLoader({show: false});
              goToResponses();
          })
            .catch((err) => console.log(err));
    
        } else {
          setSubmitModalShow(false);
        }    
      }
    
      const openFormConfigModal = () => {
        setFormConfigModal({
          show: true, 
          formId: form.id, 
          formState: form.state, 
          formSubmissionDeadline: form.submissionDeadline
        });
      }
      const closeFormConfigModal = (action) => {
        if (action !== undefined && action === 'Update') {      
          fetchSubmissionData();    
        }
        
        setFormConfigModal({show: false, formId: 0, formState: 0});    
      }
        
      const openSubmissionConfigModal = () => {
        setsubmissionConfigModal({
          show: true, 
          submissionId: submission.id, 
          submissionState: submission.state
        });
      }
      const closeSubmissionConfigModal = (action) => {
        if (action !== undefined && action === 'Update') {      
          fetchSubmissionData();    
        }
        
        setsubmissionConfigModal({show: false, submissionId: 0, submissionState: 0});    
      }

      const showTooltip = (e, header, body) => {
        setTooltip({show: true, header: header, body: body})
      }
      const closeTooltip = () => {
        setTooltip({show: false, header: '', body: ''})
      }



      const [currentOpenAccordian, setCurrentOpenAccordian] = useState(null);
    
      const openAccordian = (e, a) => {
        e.preventDefault();
        e.stopPropagation();
        
        if(currentOpenAccordian!==a.id) {
          console.log('Open Accordian', a.id);
          setCurrentOpenAccordian(a.id);
        } else {
          setCurrentOpenAccordian(null);
        }
      }
      const isOpenAccordian = (answer) => {
        if(currentOpenAccordian===answer.id) {
          console.log('Open Accordian =>', answer.id);
        }
        return currentOpenAccordian===answer.id;
      }


  return (
    <div className="page-container review">
    
        <Loader config={loader}></Loader>
        
        <FormConfigModal formConfig={formConfigModal} onHide={closeFormConfigModal}></FormConfigModal>
        <SubmissionConfigModal submissionConfig={submissionConfigModal} onHide={closeSubmissionConfigModal}></SubmissionConfigModal>
        
        <Modal show={tooltip.show} onHide={closeTooltip} center="true" closeOnOverlayClick="true"  className="form-config-modal">
            <h2>{tooltip.header}</h2>
            <p>
              <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>
              )}>
                {tooltip.body}
              </Linkify>
            </p>

            <div className="modal-footer">
              <Button variant="secondary" onClick={closeTooltip}>Close</Button>
            </div>
        </Modal>

        <Modal show={submitModalShow} onHide={closeSubmitModal} center="true">
            <h2>Submit Picks</h2>
            <p>Ready to submit your picks? Once you submit, <b>you will not be able to resubmit</b>.</p>

            <div className="modal-footer">
              <Button variant="secondary" onClick={closeSubmitModal}>Cancel</Button>
              <Button variant="primary" onClick={() => closeSubmitModal('Submit')}>Submit</Button>
            </div>
        </Modal>      

        <Modal show={statModal.show} onHide={closeStatModal} center="true" closeOnOverlayClick="true">
            <h2>{statModal.type}</h2>

            <div className="modal-footer">
              <Button variant="secondary" onClick={closeStatModal}>Close</Button>
            </div>
        </Modal>



        <div className="page-header">
          <div>
            <Button 
                variant="link" 
                onClick={(e) => goBack(e)}>
                <NavigateBeforeIcon fontSize="small" />
                Back
            </Button>
          </div>
          <h2>Review</h2>
          <div className="flex justify-content-end">
              {user.isAdmin && (<Button variant="icon" onClick={toggleFormSettings} ><SettingsIcon fontSize="small" /></Button>)}
          </div>
        </div>


        {/* <div className="flex justify-content-between">
            <ul className="breadcrumb">
                <li className="back-btn" onClick={goHome}>
                    <HomeIcon fontSize="small"/>
                    <span>Home</span>
                </li>
                <li>
                    <span>{`Round ${event.round}`}</span>
                </li>
            </ul>
            {user.isAdmin && (<Button variant="icon" onClick={toggleFormSettings} ><SettingsIcon fontSize="small" /></Button>)}
        </div> */}

        {user.isAdmin && showSettings && (
        <div className="settings-container">
            <div className="label-adorner">
            {`{ eId: ${event.id}, fId: ${form.id}, fState: ${form.state}, subId: ${submission.id} }`}
            </div>
            <div className="flex flex-wrap">
                <Button variant="outline-light" onClick={goToForm}>Edit</Button>
                <Button variant="outline-light" onClick={goToSubmission}>Preview</Button>
                <Button variant="primary" disabled>Review</Button>
                <Button variant="outline-light" onClick={goToResponses}>Responses</Button>
                <Button variant="outline-light" onClick={openFormConfigModal}>Manage Form</Button>
          <Button variant="outline-light" onClick={openSubmissionConfigModal}>Manage Sub</Button>
            </div>
        </div>
        )}



        <div className="review-container">
          <div className="dark-card event-card">
              <div className='flex justify-content-between'>
                <div>
                  <h2>{event.name}</h2>
                  <p>{`${event.datetime.toLocaleString([], {weekday: 'long' })},
                    ${event.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}
                    • ${event.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</p>
                </div>
                <img src={event.posterUrl} className='tiny-poster' alt='poster' onClick={goToEvent} />
              </div>

              <div className="flex justify-content-between">
                  <Button 
                      variant="link" 
                      onClick={(e) => goToSubmission(e)}>
                      <NavigateBeforeIcon fontSize="small" />
                      Back to Submission
                  </Button>
                  <Button 
                      variant="link" 
                      onClick={goToResponses}
                      disabled={submission.state!=='Submitted'}>
                      Responses
                      <NavigateNextIcon fontSize="small" />
                  </Button>
              </div>
            </div>


            <div className="dark-card scrollable">
              {submission.state === 'Submitted' ? (
              <div className="form-nav-subtext">

                  {[new Date(submission.tsUpdated)].map(t => (
                  <div>{`Submitted: ${t.toLocaleString([], {month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</div>
                  ))}

              </div>
              ) : (
              <div className='grid'>
                <Button 
                    variant='primary' 
                    style={{margin: '0 1em', flexShrink: 0}}
                    disabled={submission.state === 'Submitted'}
                    onClick={openSubmitModal}>

                    {isSubmitting ? (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"/>
                    ):(<>
                        Submit<SendIcon fontSize="small" style={{marginLeft: 0.5 + 'em'}} />
                    </>)}
                </Button>
              </div>
              )}

              { submission.sections.length > 0 && submission.sections[0].id !== undefined && (
              submission.sections.map(s => (
              <div className="section">
                  
                { s.title!==undefined && (
                <div className="section-header">
                    <h2>{s.title}</h2>

                    {s.showDescription && (
                    <Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                    )}
                </div>
                )}                

                <div className='section-questions-container'>
                  { s.questions.length > 0 && s.questions[0].id !== undefined && (
                  s.questions.map(q => (
                  <div>
                      <div className='question-header'>
                          <h4>{q.text}</h4>
                          
                          {q.description !== '' && (
                          <Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                          )}
                      </div>

                      {/* answer text */}
                      {q.answers[0].id!==undefined && (
                      <div className="question-answers-container">

                        {q.type === 'Driver Picks' && q.answers.map(a => (
                          <Driver driver={a} 
                            expanded={isOpenAccordian(a)} 
                            handleExpansion={openAccordian}></Driver>
                        ))}

                        {q.type === 'Constructor Picks' && q.answers.map(a => (
                          <Constructor constructor={a} 
                            expanded={isOpenAccordian(a)} 
                            handleExpansion={openAccordian}></Constructor>
                        ))}

                        {q.type === 'Bonus Radio' && q.answers.map(a => (
                          <div className={`dark-card `}>
                              
                            <div className='answer background-image-contain'
                            style={{backgroundImage: `url(${a.logoUrl})`}}>

                              <div className='answer-detail'>
                                <h4 className='single-line align-self-center'>{a.text}</h4>
                              </div>
                              
                              <div className='points'>
                                
                                {submission.state === 'Submitted' && (
                                <Chip>{a.points}</Chip>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}

                        {q.type === 'Podium Picks' && q.answers.map(a => (
                          <div className={`dark-card `}>
                              
                            <div className='answer background-image-contain'
                            style={{backgroundImage: `url(${a.logoUrl})`}}>

                              <div className='answer-detail'>
                                  <div>{a.order}</div>
                                  <div className="driverCode">{a.code}</div>
                              </div>
                              
                              <div className='points'>
                                
                                {submission.state === 'Submitted' && (
                                <Chip>{a.points}</Chip>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}

                      </div>
                      )}

                      {/* Validation failed text */}
                      <div className="review-error">
                          {q.type==='Driver Picks' && q.answers.length < 3 && (
                            <p className="validation-failed">One or more driver picks are missing</p>
                          )}

                          {q.type==='Constructor Picks' && q.answers[0].id===undefined && (
                            <p className="validation-failed">Constructor pick is missing</p>
                          )}
                          
                          {q.type==='Bonus Radio' && q.answers[0].id===undefined && (
                            <p className="validation-failed">Missing pick</p>
                          )}

                          {q.type==='Podium Picks' && q.answers.length < 3 && (
                            <p className="validation-failed">One or more podium picks are missing</p>
                          )}
                      </div>
                  </div>
                  ))
                  )}
                </div>
              </div>
              ))
              )}
          </div>
        </div>
    </div>
    )
}

export default Review;