import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/loader/loader';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


import './FormConfigModal.css';

function FormConfigModal({formConfig, onHide}) {
    const [formState, setFormState] = useState(formConfig.formState);
    const [submissionDeadline, setSubmissionDeadline] = useState(formConfig.formSubmissionDeadline);
    const [loader, setLoader] = useState({show: false, text: ''});
    
    const submitNewState = (e, action) => {
        setLoader({show: true, text: 'Setting state...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                formId: formConfig.formId, 
                state: formState
            })
        };
    
        fetch(apiUrl + "/app/form/update", requestOptions)
        .then(response => response.json())
        .then(() => {
            setFormState();
            setLoader({show: false});
            onHide(action);
        })
        .catch((err) => console.log(err));
    }

    const refreshDriverPicks = () => {
        setLoader({show: true, text: 'Refreshing Driver Picks...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                formId: formConfig.formId
            })
        };
    
        fetch(apiUrl + "/app/form/refreshDriverPicks", requestOptions)
        .then(response => {
            setLoader({show: false});
            onHide('Update');
        })
        .catch((err) => console.log(err));
    }

    const calculateResults = () => {
        setLoader({show: true, text: 'Calculating results...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                formId: formConfig.formId
            })
        };
    
        fetch(apiUrl + "/app/results/calculate", requestOptions)
        .then(response => {
            setLoader({show: false});
            onHide('Update');
        })
        .catch((err) => console.log(err));
    }

    const handleCloseEvent = () => {
        setFormState();
        onHide();
    }
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal show={formConfig.show} onHide={handleCloseEvent} closeOnOverlayClick={true} className="form-config-modal">
                <Loader config={loader}></Loader>
                <h2>Form Configuration</h2>
                <div className="label-adorner">
                {`{ formId: ${formConfig.formId}, state: ${formConfig.formState} }`}
                </div>

                <div className="divider"></div>

                {/* <DateTimePicker
                    label="Submission close date"
                    value={submissionDeadline}
                    onChange={(newValue) => setSubmissionDeadline(newValue)}
                    /> */}
                    
                
                <div className="divider"></div>

                <p><b>Calculate Results:</b></p>
                <div>
                    <Button variant='primary' onClick={refreshDriverPicks}>Refresh Driver Picks</Button>
                </div>

                <div className="divider"></div>

                <p><b>Calculate Results:</b></p>
                <div>
                    <Button variant='primary' onClick={calculateResults}>Calculate Results</Button>
                </div>

                <div className="divider"></div>

                <p><b>Select a new state for this form:</b></p>
                <div className="btn-group">
                    {formConfig.formState === 'Draft' ? (
                    <Button disabled>Draft<br />(Current)</Button>
                    ) : (
                    <Button variant={formState==='Draft' ? 'primary' : 'outline-light'} onClick={() => setFormState('Draft')}>Draft</Button>
                    )}

                    {formConfig.formState === 'Live' ? (
                    <Button disabled>Live<br />(Current)</Button>
                    ) : (
                    <Button variant={formState==='Live' ? 'primary' : 'outline-light'} onClick={() => setFormState('Live')}>Live</Button>
                    )}


                    {formConfig.formState === 'Complete' ? (
                    <Button disabled>Complete<br />(Current)</Button>
                    ) : (
                    <Button variant={formState==='Complete' ? 'primary' : 'outline-light'} onClick={() => setFormState('Complete')}>Complete</Button>
                    )}
                </div>
                
            
                {formState !== formConfig.formState && (
                <div>
                    {formState==='Draft' && (
                    <p>The form will be set to Draft. People will no longer be able to see the form. If they had already submitted picks, their submissions are still saved but they will no longer be able to see their submission.</p>
                    )}

                    {formState==='Live' && (<>
                    <p>The form will be set to Live. People will be able to fill in this form and submit their picks if it is the next event in the schedule.</p>
                    {/* <p style={{color: '#ff5252'}}>An email will be sent to all users notifying that they can now submit their picks.</p> */}
                    </>)}

                    {formState==='Complete' && (<>
                    <p>The form will be set to Complete. The banner notifying users that the results are pending will be removed and they will be able to view the full results.</p>
                    {/* <p style={{color: '#ff5252'}}>An email will be sent to all users notifying them that they results are available for them to view.</p> */}
                    </>)}
                </div>
                )}
                
                <div className="divider"></div>

                <div className="modal-footer">
                    <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
                    <Button variant="primary" onClick={(e) => submitNewState(e, 'Update')}
                        disabled={formState===formConfig.formState}>Set</Button>
                </div>

            </Modal>    
        </LocalizationProvider>

     );
    
}

export default FormConfigModal;