import React from "react"


function Loader({config}) {
  return (
    <div className={config.show ? 'loader-show' : 'loader-hide'}>
      <img className="spinner-img" src="http://d3nv2arudvw7ln.cloudfront.net/staticfolder/Tyre/resources/img/red-parentesi.png" alt="" />
      <div>{config.text}</div>
    </div>
  )
}

export default Loader;