import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button/Button';
import {DropdownButton} from '../../components/DropdownButton/DropdownButton';
import Loader from '../../components/loader/loader';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import '../schedule/schedule.css';

function PastSchedule({user}) {
    const navigate = useNavigate();
    const [year, setYear] = useState(2024);
    const [yearOptions, setYearOptions] = useState([2024, 2023]);
    const [schedule, setSchedule] = useState([]);
    const [loader, setLoader] = useState({show: false, text: ''});


    useEffect(() => {
      fetchScheduleData(year)
    }, [])

    const fetchScheduleData = (year) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            year: year,
            type: 'Past',
            userId: user.id
          })
      };
      
      setLoader({show: true, text: 'Loading Past Schedule...'});
      fetch(apiUrl + `/app/schedule`, requestOptions)
        .then(response => response.json())
        .then(data => {

            let revisedData = data.map(event => ({
              ...event,
              datetime: new Date(event.date)
            }));
            setSchedule(revisedData);
            setLoader({show: false});
        })
        .catch((err) => {
          console.log(err)
          setLoader({show: true, text: 'Error, try again.'});
        });
    }

    const goBack = () => {navigate(-1);}

    const gotopage = (e, page) => {
      e.stopPropagation();

      navigate(page);
    }

    const handleYearChange = (selectedYear) => {
      setYear(selectedYear);
      fetchScheduleData(selectedYear);      
    }


  
    return ( 
      <div className="past-schedule">
        <Loader config={loader}></Loader>


        <div className="page-header">
          <div>
            <Button 
                variant="link" 
                onClick={(e) => goBack(e)}>
                <NavigateBeforeIcon fontSize="small" />
                Back
            </Button>
          </div>
          <h2>Past Races</h2>
          <div className="flex justify-content-end">
            <DropdownButton 
              options={yearOptions}
              value={year}
              clickFunction={handleYearChange}
            >
            </DropdownButton>
          </div>
        </div>


        <div className="past-event-list">
        {schedule.length > 0 && (
          schedule.map((event, i) => (
            <div className="past-event" onClick={(e) => gotopage(e, `/event/?event=${event.eventId}`)}
              style={{backgroundImage: `linear-gradient(to bottom, 
                rgba(0, 0, 0, 0) 30%, 
                ${event.accentColor}), 
                url(${event.posterUrl})
                `}}>
                <img className="flag" alt={event.locality} src={event.flagUrl} />
                <div>{event.locality}</div>
                <h2>{event.eventName}</h2>
                <div>{`${event.datetime.toLocaleString([], {weekday: 'long' })},
                  ${event.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}
                  • ${event.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</div>
                { user.id !== null ? (
                  <div className="event-action">
                  < Button variant="primary" onClick={(e) => {gotopage(e, `/event/form/responses/?id=${event.formId}`)}}>View Results</Button>
                  </div>
                ) : ''}
            </div>
          ))
        )}
        </div>
        
        {schedule.length === 0 && (
          <div className='schedule-empty-state'>
            <h1>No races yet this season</h1>
          </div>
          
        )}
      </div>
    );
}

export default PastSchedule;