import React from 'react';
import { signInWithGoogle } from '../../firebase';
import Button from '../../components/Button/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
 
import './Auth.css';

function SignIn({ onClose }) {

    const handleOpenSignUp = () => {onClose('Open Sign Up');}

    const handleSignUpWithGoogle = async () => {
        await signInWithGoogle();
        onClose();
    }
    const handleOpenEmailSignIn = () => {onClose('Open Email Sign In');}
 
    return (
        <div className="auth-popup-container">
            <h2 className='text-align-center'>Sign In</h2>

            <p className='text-align-center'>Don't have an account? <Button variant="link" onClick={handleOpenSignUp}>Sign Up</Button></p>

            <Button variant='auth' onClick={handleSignUpWithGoogle}>
                <img src='https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg' alt='Google' />
                Sign in with Google
            </Button>

            <Button variant='auth' onClick={handleOpenEmailSignIn}>
                <MailOutlineIcon />
                Sign in with Email
            </Button>

            <Button variant="outline-light" onClick={() => onClose()}>Close</Button>

        </div>
    )
}
 
export default SignIn