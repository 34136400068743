import React, { useEffect, useState } from "react";
import { auth, sendPasswordReset } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Button from '../../components/Button/Button';

import "./Auth.css";

function Reset({ onClose }) {
  const [message, setMessage] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [email, setEmail] = useState("");
  
  useEffect(() => {
    countdown > 0 && setTimeout(() => setCountdown(countdown - 1), 1000);
  }, [countdown]);


  const handleReset = async () => {
    if(countdown===0) {
      try {
        await sendPasswordReset(email);
        setCountdown(30); //30 seconds
        console.log('password reset sent');
        
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);

        if(errorCode === 'auth/invalid-email') {
            setMessage('Invalid email.');

        } else {
            setMessage('Something went wrong.');
        }
      }

    } else {
      console.log('email timeout not exceeded', countdown);
    }
  }
  
  return (
    <div className="auth-popup-container">
        <h2>Reset Password</h2>
        
        <div className='form-field'>
            <label htmlFor="email-address">
                Email Address
            </label>
            <input
                type="email"
                label="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}  
                required
                placeholder="Email address"
            />
        </div>

        {message!=='' && (
            <p style={{color: 'red'}}>{message}</p>
        )}

        <Button variant='primary' onClick={handleReset} disabled={email==='' || countdown>0}>
            Send password reset email{countdown>0?` (${countdown})`:''}
        </Button>
        <Button variant="outline-light" onClick={onClose}>Close</Button>
    </div>
  );
}
export default Reset;