import React, { useState, useEffect } from 'react';
import './PlaceGlyph.css';

/** 
 * <PlaceGlyph place={place} />
*/
function PlaceGlyph({place}) {
    const [ordinalIndicator, setOrdinalIndicator] = useState('');
    const [glyphClass, setGlyphClass] = useState('place');
    
    useEffect(() => {
        if (place === 11 || place === 12 || place === 13) {
            setOrdinalIndicator("th");
        } else if (place % 10 === 1) {
            setOrdinalIndicator("st");
        } else if (place % 10 === 2) {
            setOrdinalIndicator("nd");
        } else if (place % 10 === 3) {
            setOrdinalIndicator("rd");
        } else {
            setOrdinalIndicator("th");
        }

        if (place == 1 ) {
            setGlyphClass('place first');
        } else if (place == 2 ) {
            setGlyphClass('place second');
        } else if (place == 3 ) {
            setGlyphClass('place third');
        }

    }, [place])


    return (
        place !== undefined && place !== null && (
        <span className={glyphClass}>{place}<sup>{ordinalIndicator}</sup></span>
        )
    )
}

export default PlaceGlyph;